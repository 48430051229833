import { Button, Card, CardActions, CardContent, Chip, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, makeStyles, MenuItem, Select, Theme, Tooltip } from "@material-ui/core";
import React, { FC, useState } from "react";
import { IApplicationsData } from "../../types/Applications";
import CloseIcon from '@material-ui/icons/Close';
import { format } from "date-fns";
import { ISquadsData } from "../../types/Squads";
import ApplicationsService from "../../services/ApplicationsService";
import SquadService from "../../services/SquadService";
import EditIcon from '@material-ui/icons/EditOutlined';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContent: {
            minHeight: '250px',
            fontFamily: 'Rubik',
            borderRadius: '20px',
            padding: '5px'
        },
        spanCard: {
            fontWeight: 'bold'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        truncate: {
            width: '250px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        dialogTitle: {
            color: 'black',
            padding: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
        },
        dialogTitleDiv: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        spanDialog: {
            fontWeight: 'normal'
        },
        divDialogContent: {
            minWidth: '500px',
            display: 'block',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Rubik',
            textAlign: 'center'
        },
        buttonCardActionEdit: {
            color: '#38A3FF',
            padding: '0px',
            '&:hover': {
                background: '#fff',
            },
        },
        cardAction: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },

        divInputInsert: {
            display: 'flex',
            flexDirection: 'column',
            margin: '20px'
        },
        divModalH3: {
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            fontWeight: 300,
            borderBottom: '1px solid #E9E9E9',
        },
        modalH3: {
            fontSize: '25px',
            lineHeight: '26px',
            color: '#0095DA',
            marginBottom: '15px'
        },
        labelFormInput: {
            color: '#091926',
            flex: 'none',
            margin: '4px',
            fontWeight: 400
        },
        formInput: {
            padding: '8px',
            gap: '8px',
            width: '100%',
            height: '40px',
            background: '#FFFFFF',
            border: '1px solid #C2C2C2',
            borderRadius: '4px',
            flex: 'none',
            alignSelf: 'stretch',
            fontSize: '16px',
            fontFamily: 'Rubik, sans-serif'
        },
        buttons: {
            border: '1px solid #0095DA',
            color: '#fff',
            boderRadius: '4px',
            backgroundColor: '#0095DA',
            '&:hover': {
                background: '#0095DA',
            }
        },
        buttonCancel: {
            color: '#0095DA',
            border: '1px solid #0095DA',
            boderRadius: '4px',
            '&:hover': {
                background: '#fff',
            }
        },
        divDialogEdit: {
            minWidth: '500px',
            display: 'block',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Rubik',
        },
        dialog: {
            borderRadius: '20px'
        },
    }),
);

const IsLate = ({ status }: any) => {
    if (!status) return null;

    if (status >= 400 && status <= 405) return <Chip style={{ backgroundColor: 'red', color: 'white' }} label={`${status}`} />

    if (status >= 500 && status <= 505) return <Chip style={{ backgroundColor: 'orange', color: 'white' }} label={`${status}`} />

    return <Chip style={{ backgroundColor: 'green', color: 'white' }} label={`${status}`} />
}

const ApplicationsCard: FC<IApplicationsData> = ({
    ambiente,
    estado,
    lastStatus,
    name,
    projeto,
    rg,
    subscricao,
    squad,
    lastUpdateDate
}) => {
    const styles = useStyles();

    const [openEdit, setOpenEdit] = useState(false);
    const [_squad, setSquad] = useState("");
    const [_project, setProject] = useState("");
    const [squads, setSquads] = useState(new Array<ISquadsData>);

    const closeEditModal = () => {
        setOpenEdit(false);
    };

    const openEditModal = () => {
        getSquads();
        setProject(projeto);
        setSquad(squad);
        setOpenEdit(true);
    };

    const getSquads = () => {
        SquadService.get()
            .then(res => {
                setSquads(res.data.data);
            }).catch((e: Error) => {
                console.debug({ ...e });
                throw e.message;
            });
    }

    const updateApplication = () => {
        ApplicationsService.updateApplication(name, _project, _squad);
        closeEditModal();
    }

    const editContent = () => {
        return (
            <div className={styles.divDialogEdit}>
                <div className={styles.divInputInsert}>
                    <label className={styles.labelFormInput}>Projeto*</label>
                    <input className={styles.formInput} value={_project} type="text" name="project" placeholder="Digite o projeto" onChange={d => setProject(d.target.value as string)} />
                    <label className={styles.labelFormInput}>Squad*</label>
                    <Select
                        required
                        className={styles.formInput}
                        value={_squad}
                        onChange={d => setSquad(d.target.value as string)}
                    >
                        {squads.map((sq) => (
                            <MenuItem key={sq.name} value={sq.name.replace("Squad ", "").replace(" ", "")}>
                                {sq.name.replace("Squad ", "").replace(" ", "")}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <DialogActions>
                    <Button
                        className={styles.buttons}
                        onClick={() => updateApplication()}
                        disabled={_project == null || _squad == null}>
                        Salvar
                    </Button>
                </DialogActions>
            </div>
        )
    }

    return (
        <>
            <Card className={styles.cardContent}>
                <CardContent>
                    <Chip label={projeto} style={{ background: '#E9E9E9' }} />
                    <IsLate status={lastStatus}></IsLate>
                    <Tooltip title={name}>
                        <p className={styles.truncate}><span className={styles.spanCard}>Nome:</span> {name}</p>
                    </Tooltip>
                    <p> <span className={styles.spanCard}>Squad:</span><Link href={`/catalog/default/group/${squad}`}> {squad}</Link></p>
                    <p><span className={styles.spanCard}>Ambiente:</span> {ambiente}</p>
                    <p><span className={styles.spanCard}>Estado:</span> {estado}</p>
                    <p><span className={styles.spanCard}>RG:</span> {rg}</p>
                    <p><span className={styles.spanCard}>Subscrição:</span> {subscricao}</p>
                    <p><span className={styles.spanCard}>Última execução:</span> {format(new Date(lastUpdateDate), 'dd/MM/yyyy HH:mm:ss')}</p>
                </CardContent>
                <CardActions className={styles.cardAction}>
                    <Button className={styles.buttonCardActionEdit} onClick={openEditModal}>
                        <EditIcon></EditIcon>
                    </Button>
                </CardActions>
            </Card>

            <Dialog
                open={openEdit}
                onClose={closeEditModal}
                maxWidth="xl"
                className={styles.dialog}
            >
                <DialogTitle className={styles.dialogTitle}>
                    <div className={styles.dialogTitleDiv}>
                        <Chip label={projeto} />
                    </div>
                    {name}
                    <IconButton
                        aria-label="close"
                        className={styles.closeButton}
                        onClick={closeEditModal}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {editContent()}
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default (
    ApplicationsCard
)