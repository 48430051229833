import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Button, createStyles, makeStyles, Grid, Dialog, DialogTitle, DialogActions, Select, MenuItem, Tooltip, Modal } from '@material-ui/core'
import { Content, Header, Page } from '@backstage/core-components'
import { ItemsMenu } from './interface/IItemsMenu';
import UploadFileBlob from './UploadImages';
import { baseUrl, deleteDocument, deleteIndex, getDocumentContent, postDocument, postIndex, token } from './azure-dev-ops';
import DialogContentMessage from './DialogContentMessage';
import SaveAsOutlinedIcon from '@material-ui/icons/SaveOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import ArrowBackOutlined from '@material-ui/icons/ArrowBackOutlined';
import SectionCollapseSimple from './SectionCollapseSimple';
import SectionCollapseMenu from './SectionCollapseMenu';
import HeaderMarkdown from './HeaderMarkdown';
import SectionCollapseMarkdown from './SectionCollapseMarkdown';
import { useUserProfile } from '@backstage/plugin-user-settings';
import { getAllSquads } from '../squads/context/SquadsContext';
import { ISquads } from '../squads/interface/ISquads';

const useStyles = makeStyles(() =>
    createStyles({
        backgroundMarkdown: {
            display: 'flex',
            width: '100%',
            height: '100vh',
            backgroundColor: '#E9E9E9',
            margin: '4px',
            marginTop: '35px',
            borderRadius: '4px'
        },
        wrapperMarkdown: {
            width: '85%',
            marginTop: '15px',
            marginBottom: '15px',
            marginRight: '15px',
            background: '#fff',
            border: 'none',
            borderRadius: '4px'
        },
        formInputDocName: {
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '8px',
            gap: '8px',
            width: '95%',
            height: '40px',
            background: '#FFFFFF',
            border: '1px solid #C2C2C2',
            borderRadius: '4px',
            flex: 'none',
            alignSelf: 'stretch',
            fontSize: '16px',
        },
        formInputEditTitleName: {
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '8px',
            gap: '8px',
            width: '100%',
            height: '40px',
            background: '#FFFFFF',
            border: '1px solid #C2C2C2',
            borderRadius: '4px',
            flex: 'none',
            alignSelf: 'stretch',
            fontSize: '16px',
            fontFamily: 'Rubik, sans-serif'
        },
        wrapperModalEditTitle: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            height: '22%',
            margin: '15px',
            background: '#fff',
            borderRadius: '4px',
            fontFamily: 'Rubik, sans-serif'
        },
        form: {
            fontStyle: 'normal',
            fontSize: '14px',
            lineHeight: '21px',
            color: '#091926',
            flex: 'none',
            margin: '4px',
            fontWeight: 400
        },
        formInputDocSquad: {
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '8px',
            gap: '8px',
            width: '100%',
            height: '40px',
            background: '#FFFFFF',
            border: '1px solid #C2C2C2',
            borderRadius: '4px',
            flex: 'none',
            alignSelf: 'stretch',
            fontSize: '16px',
        },
        formInputDocDescription: {
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            textAlign: 'start',
            padding: '8px',
            gap: '8px',
            width: '95%',
            height: '80px',
            background: '#FFFFFF',
            border: '1px solid #C2C2C2',
            borderRadius: '4px',
            flex: 'none',
            alignSelf: 'stretch',
            fontSize: '16px',
            outline: 'none !important',
            resize: 'none',
            fontFamily: 'Rubik, sans-serif'
        },
        formInputDocTags: {
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            textAlign: 'start',
            padding: '8px',
            gap: '8px',
            width: '100%',
            height: '80px',
            background: '#FFFFFF',
            border: '1px solid #C2C2C2',
            borderRadius: '4px',
            flex: 'none',
            alignSelf: 'stretch',
            fontSize: '16px',
            outline: 'none !important',
            resize: 'none',
            fontFamily: 'Rubik, sans-serif'
        },
        textareaMarkdown: {
            border: 'none',
            borderRadius: '4px',
            outline: 'none !important',
            width: '100%',
            height: '100%',
            overflowX: 'hidden',
            fontSize: '17px',
            resize: 'none',
            background: '#fff',
            padding: '25px',
            '&::-webkit-scrollbar': {
                display: 'none',
            }
        },
        buttons: {
            border: '1px solid #0095DA',
            color: '#fff',
            boderRadius: '4px',
            backgroundColor: '#0095DA',
            '&:hover': {
                background: '#0095DA',
            }
        },
        buttonCancel: {
            color: '#0095DA',
            border: '1px solid #0095DA',
            boderRadius: '4px',
            '&:hover': {
                background: '#fff',
            }
        },
        ulList: {
            listStyleType: 'none',
            display: 'flex',
            margin: '0',
            padding: '4px'
        },
        iconSaveDocEnabled: {
            float: 'right',
            color: '#0095DA',
            cursor: 'pointer',
            '&:hover': {
                background: '#D8EDFF',
                borderRadius: '50%'
            }
        },
        iconSaveDocDisable: {
            float: 'right',
            color: '#E9E9E9',
        },
        buttonDelete: {
            border: '1px solid #FF3535',
            float: 'right',
            color: '#fff',
            boderRadius: '4px',
            backgroundColor: '#FF3535',
            '&:hover': {
                background: "#992020",
            }
        },
        iconDeleteIndex: {
            display: 'inherit',
            margin: '0px',
            right: '10px',
            padding: '0px',
            color: '#FF3535',
            borderRadius: '50%',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#FCA5A5'
            }
        },
        iconEditIndex: {
            color: '#0095DA',
            margin: '0px',
            right: '10px',
            padding: '0px',
            borderRadius: '50%',
            cursor: 'pointer',
            '&:hover': {
                background: '#D8EDFF',
                borderRadius: '50%'
            }
        },
        iconInsertIndex: {
            color: '#0095DA',
            width: '28px',
            height: '28px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #0095DA',
            borderRadius: '4px',
            margin: '4px'
        },
        formInputMenuName: {
            width: '100%',
            height: '28px',
            border: 'none',
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '21px',
            backgroundColor: '#C2C2C2',
            color: '#091926',
            '&::placeholder': {
                color: '#091926'
            }
        },
        buttonInsertIndex: {
            margin: '0px',
            minWidth: '28px',
            height: '28px',
            padding: '0px',
            color: '#FF3535',
            '&:hover': {
                backgroundColor: 'transparent'
            }
        },
        buttonReturn: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: '4px 14px',
            background: '#0095DA',
            borderRadius: '4px',
            fontSize: '15px',
            color: '#FFFFFF',
            width: '100px',
            bottom: '10px',
            '&:hover': {
                background: '#D8EDFF',
                color: 'black'
            }
        }
    }),
);

const saveIndexAD = async (docName: string, docDescription: string, ownerName: string, items: any[], author?: string) => {
    return await postIndex(docName, docDescription, ownerName, items, author);
}

const saveDocumentAD = async (docName: string, docPathName: string, content: string) => {
    console.log('docPathName', docPathName);
    return await postDocument(docName, docPathName, content);
}

const deleteDoc = async (docName: string) => {
    return await deleteDocument(docName);
}

const getDocName = () => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var data = url.searchParams.get("docName");

    if (data != null)
        return data;
    else
        return "";
}

const getContent = (docName: string, pathName: string) => {
    var content = getDocumentContent(docName, pathName)
        .then(result => {
            if (result != null)
                return result;
            return "";
        });
    return content;
}

const LiveMarkdown = () => {
    const styles = useStyles();
    let author = useUserProfile().displayName.toLowerCase().replaceAll(" ", ".");

    const [markdownInput, setMarkdownInput] = useState("");
    const [docName, setDocName] = useState(getDocName());
    const [menuName, setMenuName] = useState("");
    const [docDescription, setDocDescription] = useState("");
    const [docTags, setDocTags] = useState("");
    const [ownerName, setOwnerName] = useState("");
    const [currentPathName, setCurrentPathName] = useState("");
    const [items, setItems] = useState(new Array<ItemsMenu>());
    const [openSalveDoc, setOpenSalveDoc] = useState(false);
    const [loadSalveDoc, setLoadSalveDoc] = useState(false);
    const [messageSalveDoc, setMessageSalveDoc] = useState("");
    const [openModalDeleteDocs, setOpenModalDeleteDocs] = useState(false);
    const [messageDeleteItems, setMessageDeleteItems] = useState("");
    const [openDeleteItems, setOpenDeleteItems] = useState(false);
    const [openIndexDelete, setOpenIndexDelete] = useState(false);
    const [disableDeleteItems, setDisableDeleteItems] = useState(true);
    const [lastUpdate, setLastUpdate] = useState("");
    const [getAuthor, setGetAuthor] = useState("");
    const [openEdit, setOpenEdit] = useState(false);
    const [newTitleName, setNewTitleName] = useState("");
    const [squads, setSquads] = useState(new Array<ISquads>());

    const handleClose = () => {
        setLoadSalveDoc(false);
        setOpenSalveDoc(false);
        setOpenModalDeleteDocs(false);
        setOpenDeleteItems(false);
        setOpenIndexDelete(false);
    };

    const openDeleteModal = () => {
        setOpenModalDeleteDocs(true);
    }

    const getSquads = async () => {
        setSquads(await getAllSquads());
    }

    const AddZero = (num: number) => {
        if (num <= 9)
            return "0" + num;
        else
            return num;
    }

    useEffect(() => {
        getMkdocsTags();
    }, [lastUpdate]);

    async function getMkdocsTags() {
        getSquads();
        let patKey = token();
        await axios.get(baseUrl() + docName + '/mkdocs-tags'
            , {
                headers: {
                    'Content-type': 'application/json',
                    'Pat-Key': patKey
                }
            })
            .then(res => {
                let itemsArray = new Array<ItemsMenu>();
                setDocDescription(res.data.data.description);
                setOwnerName(res.data.data.owner);
                setGetAuthor(res.data.data.author);
                let date = new Date(res.data.data.updatedAt);
                setLastUpdate(AddZero(date.getDate()) + "/" + (AddZero((date.getMonth() + 1))) + "/" + (date.getFullYear()) + " às " + date.getHours() + ":" + AddZero(date.getMinutes()));
                res.data.data.contentDocuments.map((item: any, key: string) => {
                    itemsArray.push({
                        key
                        , title: item.title
                        , pathName: item.fileName
                    });
                })
                setItems(itemsArray);
            })
            .catch((e: Error) => {
                console.log('error:', { ...e });
            });
    }

    const mkInput = (input: string) => {
        setMarkdownInput(input);
    };

    const deleteIndexDoc = async () => {
        var message = await deleteIndex(docName, currentPathName);
        setMessageDeleteItems(message.message);
        setOpenDeleteItems(true);
        var newItems = items.filter(it => it.pathName != currentPathName);
        setItems(newItems);
        setCurrentPathName("");
        setMarkdownInput("");
        setOpenIndexDelete(false);
    }

    const HederTitle = () => {
        var name = docName == "" ? "" : "> " + docName;
        return "Documentação de desenvolvimento " + name;
    }

    const insertItens = () => {
        let id = items.length + 1;

        if (id == 1) {
            items.push({
                key: id.toString()
                , title: menuName
                , pathName: 'index.md'
            });
        }
        else {
            items.push({
                key: id.toString()
                , title: menuName
                , pathName: menuName.replaceAll(" ", "").toLowerCase() + '.md'
            });
        }

        setMenuName("");
    }

    const saveDocument = async (author?: string) => {
        setLoadSalveDoc(true);
        var messageSaveIndex = await saveIndexAD(docName.replaceAll(" ", "-"), docDescription, ownerName, items, author);
        setMessageSalveDoc(messageSaveIndex.message);
        if (markdownInput != "") {
            var data = await saveDocumentAD(docName.replaceAll(" ", "-"), currentPathName, markdownInput);
            setMessageSalveDoc(data.message);
            let date = new Date(data.data.updatedAt);
            setLastUpdate(AddZero(date.getDate()) + "/" + (AddZero((date.getMonth() + 1))) + "/" + (date.getFullYear()) + " às " + date.getHours() + ":" + AddZero(date.getMinutes()));
        }
        setOpenSalveDoc(true);
    }

    const getDocContent = async (docName: string, pathName: string) => {
        var content = await getContent(docName, pathName);

        if (content == "") {
            setDisableDeleteItems(true);
        }
        else {
            setDisableDeleteItems(false);
        }

        setCurrentPathName(pathName);
        mkInput(await content);
    }

    const editIndex = (oldMenuIndex: string) => {
        let itemMenu = items.find(i => i.pathName == oldMenuIndex) as ItemsMenu;

        if (itemMenu == null) {
            return (<></>)
        }
        else {
            return (
                <div>
                    <Modal
                        open={openEdit}
                    >
                        <div className={styles.wrapperModalEditTitle}>
                            <div style={{
                                borderBottom: '2px solid #BBDDFA',
                                padding: '5px',
                                alignItems: 'center',
                                textAlign: 'start',
                                fontSize: '20px',
                                color: 'black',
                                width: '100%',
                                fontFamily: 'Rubik, sans-serif',
                                height: '35%'
                            }}>
                                <label style={{ marginRight: '0px', marginTop: '0px', marginBottom: '0px', marginLeft: '25px', fontSize: '25px' }}>Editar nome da página</label><br />
                                <label style={{ marginRight: '0px', marginTop: '0px', marginBottom: '0px', marginLeft: '25px', fontSize: '15px' }}>Edite o nome da página desejada.</label>
                            </div>
                            <div style={{ padding: '8px', margin: '0px' }}>
                                <label className={styles.form}>Novo nome da página</label>
                                <input
                                    className={styles.formInputEditTitleName}
                                    type="text"
                                    name="newTitleName"
                                    value={newTitleName}
                                    placeholder="Novo nome da página"
                                    onChange={d => setNewTitleName(d.target.value as string)}
                                />
                            </div>
                            <DialogActions>
                                <Button className={styles.buttonCancel} onClick={() => setOpenEdit(false)}>Cancelar</Button>
                                <Button
                                    className={styles.buttons}
                                    onClick={() => saveNewTitleName(itemMenu)}
                                    disabled={newTitleName == null || newTitleName == ""}
                                >
                                    Salvar
                                </Button>
                            </DialogActions>
                        </div>
                    </Modal>
                </div>
            )
        }
    }

    const saveNewTitleName = (itemMenu: ItemsMenu) => {
        const index = items.indexOf(itemMenu);
        items[index].title = newTitleName;
        setOpenEdit(false);
        setItems(items);
    }

    const MenuList = (menuItems: any[]) => {
        let contentMenuList = menuItems.length > 0 ? (menuItems.map((menu) =>
            <nav key={menu.key}>
                <ul className={styles.ulList}>
                    <li>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <label
                                style={{ fontSize: '17px', width: '100%', cursor: 'pointer', margin: '0', padding: '2px' }}
                                onClick={() => getDocContent(docName, menu.pathName)}
                            >
                                {currentPathName == menu.pathName ? <b>{menu.title}</b> : menu.title}
                            </label>

                            {
                                currentPathName == menu.pathName
                                    ?
                                    <>
                                        <Tooltip title="Editar página">
                                            <EditIcon
                                                className={styles.iconEditIndex}
                                                onClick={() => setOpenEdit(true)}
                                            />
                                        </Tooltip>
                                        {editIndex(currentPathName)}
                                    </>
                                    :
                                    <></>
                            }

                            {
                                currentPathName == menu.pathName && !(currentPathName == "" || currentPathName == "index.md" || disableDeleteItems)
                                    ?
                                    <Tooltip title="Deletar página">
                                        <DeleteIcon className={styles.iconDeleteIndex} onClick={() => setOpenIndexDelete(true)} />
                                    </Tooltip>
                                    :
                                    <></>
                            }
                        </div>
                    </li>
                </ul>
            </nav>)
        ) : <></>
        return (
            <>
                {contentMenuList}
            </>
        )
    }

    const ModalAlert = (message: string, title: string, openValue: boolean) => {
        return (
            <div>
                <Dialog
                    open={openValue}
                    keepMounted
                >
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContentMessage message={message}></DialogContentMessage>
                    <DialogActions>
                        <Button className={styles.buttons} onClick={handleClose}>OK</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    const ModalLoadSaveDocs = (message: string, title: string, openValue: boolean) => {
        return (
            <div>
                <Dialog
                    open={openValue}
                    keepMounted
                >
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContentMessage message={message}></DialogContentMessage>
                </Dialog>
            </div>
        );
    }

    const DeleteDocumentModal = (openModal: boolean) => {
        return (
            <>
                <Dialog
                    open={openModal}
                    keepMounted
                >
                    <DialogTitle>{"Excluir"}</DialogTitle>
                    <DialogContentMessage message={"Deseja mesmo excluir o documento '" + docName + "'?"}></DialogContentMessage>
                    <DialogActions>
                        <Button className={styles.buttons} onClick={handleClose}>Não</Button>
                        <a href='/docs'>
                            <Button style={{ backgroundColor: '#FF3535' }} onClick={() => deleteDoc(docName)}>Sim</Button>
                        </a>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    const DeleteIndexModal = (openModal: boolean) => {
        return (
            <>
                <Dialog
                    open={openModal}
                    keepMounted
                >
                    <DialogTitle>{"Excluir arquivo"}</DialogTitle>
                    <DialogContentMessage message={"Deseja mesmo excluir o arquivo '" + currentPathName + "'?"}></DialogContentMessage>
                    <DialogActions>
                        <Button className={styles.buttons} onClick={handleClose}>Não</Button>
                        <Button style={{ backgroundColor: '#FF3535', color: '#fff' }} onClick={deleteIndexDoc}>Sim</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    const SaveDocumentEnabled = () => {
        if (currentPathName == ""
            || currentPathName == null
            || markdownInput == ""
            || docName == ""
            || docDescription == ""
            || ownerName == "") {
            return (
                <Tooltip title="Salvar">
                    <SaveAsOutlinedIcon
                        className={styles.iconSaveDocDisable}>
                    </SaveAsOutlinedIcon>
                </Tooltip>
            )
        }
        else {
            return (
                <Tooltip title="Salvar">
                    <SaveAsOutlinedIcon
                        className={styles.iconSaveDocEnabled}
                        onClick={() => saveDocument(author)}>
                    </SaveAsOutlinedIcon>
                </Tooltip>
            )
        }
    }

    const MarkdownTextArea = () => {
        return (
            (markdownInput != "" || currentPathName != "")
                ?
                <>
                    <div style={{ display: 'flex', width: '100%', height: '85%' }}>
                        <textarea
                            autoFocus={false}
                            className={styles.textareaMarkdown}
                            value={markdownInput}
                            onChange={(e) => mkInput(e.target.value)}
                            style={{ fontFamily: 'Rubik, sans-serif' }}
                        ></textarea>
                        <SectionCollapseMarkdown markdownInput={markdownInput} />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <label>Última atualização: <b>{lastUpdate}</b></label>
                        <label>, autor: <b>{getAuthor}</b></label>
                    </div>
                </>
                :
                <div style={{ textAlign: 'center', margin: '4px' }}>
                    <h2 style={{ color: '#666666', fontSize: '32px' }}>Sem título selecionado</h2>
                    <label>Última atualização: <b>{lastUpdate}</b></label><br/>
                    <label>Autor: <b>{getAuthor}</b></label>
                </div>
        )
    }

    const validateDocName = (event: any) => {
        var regex = new RegExp("^[ 0-9a-zA-Z\b]+$");
        var name = event.target.value.toLowerCase();
        if (regex.test(name))
            setDocName(name);
    }

    return (
        <Page themeId='home'>
            <Header title={HederTitle()}></Header>
            <Content>
                <div style={{ fontFamily: 'Rubik, sans-serif' }}>
                    <Grid container spacing={1}>
                        <div style={{ width: '100%' }}>
                            <div>
                                <Button className={styles.buttonReturn}>
                                    <a href='/docs' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <ArrowBackOutlined style={{ fontSize: '20px' }} /> Voltar
                                    </a>
                                </Button>
                            </div>

                            <SectionCollapseSimple title="Informações da documentação">
                                <div>
                                    <div style={{ padding: '8px', margin: '10px' }}>
                                        <div style={{ display: 'inline-flex', width: '100%' }}>
                                            <div style={{ width: '55%' }}>
                                                <label className={styles.form}>Nome da documentação *</label>
                                                <input required className={styles.formInputDocName} pattern="^[a-zA-Z0-9]+$" type="text" name="docName" value={docName} onChange={d => validateDocName(d)} disabled={items.length > 0} placeholder="Nome da documentação" />
                                            </div>
                                            <div style={{ width: '45%' }}>
                                                <label className={styles.form}>Selecione a squad *</label>
                                                <Select
                                                    required
                                                    value={ownerName}
                                                    onChange={d => setOwnerName(d.target.value as string)}
                                                    className={styles.formInputDocSquad}
                                                >
                                                    {squads.map((sq) => (
                                                        <MenuItem key={sq.name} value={sq.name}>
                                                            {sq.name}
                                                        </MenuItem>
                                                    ))}

                                                </Select>
                                            </div>
                                        </div>

                                        <div style={{ display: 'inline-flex', width: '100%', marginTop: '20px' }}>

                                            <div style={{ width: '60%' }}>
                                                <label className={styles.form}>Descrição *</label>
                                                <textarea required className={styles.formInputDocDescription} name="docDescription" value={docDescription} onChange={d => setDocDescription(d.target.value)} placeholder="Descrição" maxLength={400}/>
                                            </div>

                                            {/* <div style={{ width: '40%' }}>
                                                <label className={styles.form}>Tags (ex.: "DevPack;Institucional")</label>
                                                <textarea className={styles.formInputDocTags} name="docTags" value={docTags} onChange={d => setDocTags(d.target.value)} placeholder="Adicionar tags" />
                                            </div> */}

                                        </div>

                                        {/* <Button className={styles.buttonDelete} onClick={openDeleteModal}>
                                            <DeleteIcon></DeleteIcon>
                                        </Button> */}

                                    </div>
                                </div>
                            </SectionCollapseSimple>
                        </div>
                    </Grid>
                    <Grid container spacing={1}>
                        <div className={styles.backgroundMarkdown}>

                            <SectionCollapseMenu title="Menu">
                                {MenuList(items)}

                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Tooltip title="Adicionar página">
                                        <Button
                                            className={styles.buttonInsertIndex}
                                            onClick={insertItens}
                                            disabled={menuName == "" || menuName == null}>
                                            <AddOutlinedIcon className={styles.iconInsertIndex}></AddOutlinedIcon>
                                        </Button>
                                    </Tooltip>
                                    <input className={styles.formInputMenuName} type="text" name="menuName" value={menuName} onChange={d => setMenuName(d.target.value)} placeholder="Nova página" />
                                </div>
                            </SectionCollapseMenu>

                            <div className={styles.wrapperMarkdown}>
                                <div>
                                    {ModalLoadSaveDocs("Salvando sua documentação.", "Salvando...", loadSalveDoc)}
                                    {ModalAlert(messageSalveDoc, "Salvo", openSalveDoc)}
                                    {ModalAlert(messageDeleteItems, "Excluído", openDeleteItems)}
                                    {DeleteDocumentModal(openModalDeleteDocs)}
                                    {DeleteIndexModal(openIndexDelete)}

                                    <HeaderMarkdown>
                                        {SaveDocumentEnabled()}
                                        <UploadFileBlob documentName={docName}></UploadFileBlob>
                                    </HeaderMarkdown>
                                </div>
                                {MarkdownTextArea()}
                            </div>

                        </div>
                    </Grid>
                </div>
            </Content>
        </Page>
    )
}

export default (
    <LiveMarkdown></LiveMarkdown>
)