import axios from "axios";

export const baseUrl = () => {
    return "https://backstage-util-prd.azurewebsites.net/api/v1.0/note/";
    // return "https://localhost:5101/api/v1.0/note/";
}

export const getNotes = async (userName: string) => {
    var notes = await axios.get(baseUrl() + userName + "/note"
        , {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => {
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error: ', { ...e });
        });

    return notes;
}

export const postNote = async (userName: string, noteList: any) => {
    const notes = Array.from(noteList);

    var body: any = '{ "notesContentCommands": [';

    notes.map((n: any) => {
        if (n != null)
            body += '{ "noteName": "' + n.noteName + '", "noteContent": "' + n.noteContent + '"},';
    });

    body = body.substring(0, body.length - 1);
    body += '] }';

    return await axios.post(baseUrl() + userName + "/save-note"
        , body
        , {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => {
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error:', { ...e });
        });
}

export const deleteNote = async (userName: string, idNote: string) => {
    return await axios.post(baseUrl() + userName + "/delete-note"
        , '{ "idNote": "' + idNote + '" }'
        , {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => {
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error: ', { ...e });
        });
}

export const updateNote = async (userName: string, idNoteContent: string, noteName: string, noteContent: string) => {
    var body = '{ "idNoteContent": "' + idNoteContent + '", "noteName": "' + noteName + '", "noteContent": "' + noteContent + '" }';

    return await axios.post(baseUrl() + userName + "/update-note"
        , body
        , {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => {
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error: ', { ...e });
        });
}