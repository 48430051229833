import { AnnouncementsCard } from '@k-phoen/backstage-plugin-announcements';
import { Card, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            borderRadius: '20px',
            gap: '14px',
            margin: '30px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
            border: '1px solid #E9E9E9',
            width: '100%',
        }
    }),
);

export const Announcements = () => {
    const styles = useStyles();

    return (
        <>
            <Card className={styles.card}>
                <div style={{ width: '100%', marginBottom: '-10px' }}>
                    <AnnouncementsCard title='Novidades' max={10} />
                </div>
            </Card>
        </>
    );
};