import { Button, Card, CardContent, Checkbox, createStyles, FormControlLabel, FormGroup, makeStyles, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { IChecklist } from '../interface/IChecklist';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import { deleteChecklistItems, getChecklist, postChecklistItems, updateIsCheck } from '../context/ChecklistContext';

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            maxHeight: '500px',
            borderRadius: '20px',
            gap: '14px',
            margin: '30px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
            border: '1px solid #E9E9E9',
            width: '100%',
        },
        cardContent: {
            padding: '0px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '5px'
            },
            '&::-webkit-scrollbar-track': {
                background: '#FCFCFC'
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#AAB1B5',
                borderRadius: '13px',
            }
        },
        divH3: {
            padding: '0px 40px 0px 40px',
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            fontWeight: 300,
            borderBottom: '1px solid #E9E9E9',
        },
        h3: {
            fontSize: '25px',
            lineHeight: '26px',
            color: '#0095DA'
        },
        iconInsertItem: {
            color: '#0095DA',
            width: '28px',
            height: '28px',
            backgroundColor: '#FFFFFF',
            border: 'none',
            borderRadius: '4px'
        },
        formInputItem: {
            width: '100%',
            height: '28px',
            border: 'none',
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '15px',
            marginRight: '10px',
            marginLeft: '10px',
            backgroundColor: 'white',
            color: '#091926',
            '&::placeholder': {
                color: '#091926',
            }
        },
        buttonInsertItem: {
            margin: '0px',
            minWidth: '28px',
            height: '28px',
            padding: '0px',
            color: '#FF3535',
            '&:hover': {
                backgroundColor: 'transparent'
            }
        },
        divButtonInsertItem: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
            padding: '20px 40px 20px 40px',
            borderTop: '1px solid #E9E9E9',
        },
        iconSyncItem: {
            color: '#0095DA',
            width: '28px',
            height: '28px',
            backgroundColor: '#FFFFFF',
            border: 'none',
            borderRadius: '4px',
            marginRight: '40px',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: 'transparent'
            }
        },
        labelName: {
            fontSize: '18px'
        },
    }),
);

const GetChecklist = async (userName: string) => {
    return await getChecklist(userName).then(res => {
        if (res.data == null)
            return null;
        return res.data.checklistsItems;
    })
}

export const Checklist = (props: { userName: string }) => {
    const styles = useStyles();
    const [checklist, setChecklistItem] = useState("");
    const [listChecklist, setListChecklist] = useState(new Array<IChecklist>);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        getChecklistsItems();
    }, [refresh]);

    async function getChecklistsItems() {
        let checklist = await GetChecklist(props.userName);
        let checklistItems = new Array<IChecklist>;

        if (checklist != null) {
            checklist.map((c: any) => {
                checklistItems.push({
                    id: c.id,
                    name: c.name,
                    isCheck: c.isCheck,
                    isProcessCheck: c.isProcessCheck
                });
            });
        }
        setListChecklist(checklistItems);
    }

    async function insertChecklistItem() {
        if (checklist == "")
            return;

        listChecklist.push({
            name: checklist,
            isCheck: false,
            isProcessCheck: false
        });

        await postChecklistItems(props.userName, listChecklist);
        setChecklistItem("");
        setRefresh(!refresh);
    }

    async function updateChecked(idChecklistsItems: any, isCheck: boolean) {
        await updateIsCheck(props.userName, idChecklistsItems, isCheck);
        setRefresh(!refresh);
    }

    async function deleteChecklist() {
        await deleteChecklistItems(props.userName);
        setRefresh(!refresh);
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            insertChecklistItem();
        }
    };

    return (
        <>
            <Card className={styles.card}>
                <CardContent className={styles.cardContent}>
                    <div className={styles.divH3}>
                        <h3 className={styles.h3}>Checklist</h3>
                    </div>
                    <FormGroup style={{ padding: '20px 40px 0px 40px' }}>
                        {
                            listChecklist.length != 0
                            ?
                            listChecklist.map((c) => (
                                <FormControlLabel control={<Checkbox checked={c.isCheck} onClick={() => updateChecked(c.id, !c.isCheck)} />} label={c.name} />
                            ))
                            :
                            <label className={styles.labelName}>Nenhum checklist criado.</label>
                        }
                    </FormGroup>
                </CardContent>
                <div className={styles.divButtonInsertItem}>
                    <Tooltip title="Adicionar item">
                        <Button
                            className={styles.buttonInsertItem}
                            onClick={insertChecklistItem}>
                            <AddOutlinedIcon className={styles.iconInsertItem}></AddOutlinedIcon>
                        </Button>
                    </Tooltip>
                    <input className={styles.formInputItem} type="text" value={checklist} onChange={d => setChecklistItem(d.target.value)} placeholder="NOVO ITEM" onKeyDown={handleKeyDown} />
                    <Tooltip title="Sincronizar itens">
                        <SyncOutlinedIcon className={styles.iconSyncItem} onClick={() => deleteChecklist()}></SyncOutlinedIcon>
                    </Tooltip>
                </div>
            </Card>
        </>
    )
}