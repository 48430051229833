import { Button, Card, CardContent, createStyles, DialogActions, FormGroup, makeStyles, Modal, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { IContact } from '../interface/IContact';
import { deleteContact, getContacts, postContact, updateContact } from '../context/ContactContext';

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            maxHeight: '500px',
            borderRadius: '20px',
            gap: '14px',
            margin: '30px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
            border: '1px solid #E9E9E9',
            width: '100%'
        },
        cardContent: {
            padding: '0px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '5px'
            },
            '&::-webkit-scrollbar-track': {
                background: '#FCFCFC'
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#AAB1B5',
                borderRadius: '13px',
            }
        },
        // div: {
        //     fontFamily: 'Rubik',
        //     fontStyle: 'normal',
        //     fontWeight: 300,
        // },
        divH3: {
            padding: '0px 40px 0px 40px',
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            fontWeight: 300,
            borderBottom: '1px solid #E9E9E9',
        },
        h3: {
            fontSize: '25px',
            lineHeight: '26px',
            color: '#0095DA',
            marginBottom: '0px'
        },
        h4: {
            marginTop: '5px'
        },
        divContent: {
            fontSize: '16px',
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
        },
        labelName: {
            fontSize: '18px'
        },
        labelEmail: {
            borderBottom: '1px solid #0095DA',
            marginBottom: '10px'
        },
        divButtonInsert: {
            marginTop: '10px',
            padding: '20px 40px 20px 40px',
            borderTop: '1px solid #E9E9E9',
        },
        buttonInsertContact: {
            margin: '0px',
            minWidth: '28px',
            height: '28px',
            padding: '0px',
            '&:hover': {
                backgroundColor: 'transparent'
            }
        },
        iconInsertItem: {
            color: '#0095DA',
            width: '28px',
            height: '28px',
            backgroundColor: '#FFFFFF',
            border: 'none',
            borderRadius: '4px',
        },
        labelButtonInsert: {
            marginLeft: '15px',
            cursor: 'pointer',
            fontWeight: 400,
            fontSize: '15px',
        },
        wrapperModalInsertTitle: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            height: '350px',
            margin: '30px',
            padding: '20px 40px 20px 40px',
            background: '#fff',
            borderRadius: '20px',
            fontFamily: 'Rubik',
            fontStyle: 'normal',
        },
        divInputInsert: {
            display: 'flex',
            flexDirection: 'column',
            margin: '20px'
        },
        divModalH3: {
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            fontWeight: 300,
            borderBottom: '1px solid #E9E9E9',
        },
        modalH3: {
            fontSize: '25px',
            lineHeight: '26px',
            color: '#0095DA',
            marginBottom: '15px'
        },
        labelFormInput: {
            color: '#091926',
            flex: 'none',
            margin: '4px',
            fontWeight: 400
        },
        formInput: {
            padding: '8px',
            gap: '8px',
            width: '100%',
            height: '40px',
            background: '#FFFFFF',
            border: '1px solid #C2C2C2',
            borderRadius: '4px',
            flex: 'none',
            alignSelf: 'stretch',
            fontSize: '16px',
            fontFamily: 'Rubik, sans-serif'
        },
        buttons: {
            border: '1px solid #0095DA',
            color: '#fff',
            boderRadius: '4px',
            backgroundColor: '#0095DA',
            '&:hover': {
                background: '#0095DA',
            }
        },
        buttonCancel: {
            color: '#0095DA',
            border: '1px solid #0095DA',
            boderRadius: '4px',
            '&:hover': {
                background: '#fff',
            }
        },
        buttonDelete: {
            color: '#fff',
            border: '1px solid #FF3535',
            boderRadius: '4px',
            backgroundColor: '#FF3535',
            '&:hover': {
                background: '#FF3535',
            }
        }
    }),
);

const GetContacts = async (userName: string) => {
    return await getContacts(userName).then(res => {
        if (res.data == null)
            return null;
        return res.data.contacts;
    })
}

export const Contacts = (props: { userName: string }) => {
    const styles = useStyles();

    const [openInsert, setOpenInsert] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [listContact, setListContact] = useState(new Array<IContact>);
    const [refresh, setRefresh] = useState(false);
    const [nameContact, setNameContact] = useState("");
    const [emailContact, setEmailContact] = useState("");
    const [idContact, setIdContact] = useState("");

    useEffect(() => {
        getChecklistsItems();
    }, [refresh]);

    async function getChecklistsItems() {
        var bdContacts = await GetContacts(props.userName);
        var contacts = new Array<IContact>;

        if (bdContacts != null) {
            bdContacts.map((c: IContact) => {
                contacts.push(
                    {
                        id: c.id
                        , name: c.name
                        , email: c.email
                    }
                )
            });
        }
        setListContact(contacts);
    }

    async function insertContact() {
        var newContact: IContact = {
            name: nameContact
            , email: emailContact
        }

        listContact.push(newContact);
        await postContact(props.userName, listContact);
        setOpenInsert(false);
        setNameContact("");
        setEmailContact("");
        setRefresh(!refresh);
    }

    const setOpenModalEdit = (name: string, email: string, id?: string) => {
        setNameContact(name);
        setEmailContact(email);
        setIdContact(id as string);
        setOpenEdit(true);
    }

    async function deleteOneContact() {
        await deleteContact(props.userName, idContact);
        setOpenEdit(false);
        setNameContact("");
        setEmailContact("");
        setIdContact("");
        setRefresh(!refresh);
    }

    async function updateOneContact() {
        await updateContact(props.userName, idContact, nameContact, emailContact);
        setOpenEdit(false);
        setNameContact("");
        setEmailContact("");
        setIdContact("");
        setRefresh(!refresh);
    }

    const modalEditContact = () => {
        return (
            <div>
                <Modal
                    open={openEdit}
                >
                    <div className={styles.wrapperModalInsertTitle}>
                        <div className={styles.divModalH3}>
                            <h3 className={styles.modalH3}>Adicionar novo contato</h3>
                        </div>
                        <div className={styles.divInputInsert}>
                            <label className={styles.labelFormInput}>Nome do contato*</label>
                            <input className={styles.formInput} value={nameContact} type="text" name="nameContact" placeholder="Digite o nome do contato" onChange={d => setNameContact(d.target.value as string)} />
                            <label className={styles.labelFormInput}>Email do contato*</label>
                            <input className={styles.formInput} value={emailContact} type="text" name="emailContact" placeholder="Digite o email do contato" onChange={d => setEmailContact(d.target.value as string)} />
                        </div>
                        <DialogActions>
                            <Button className={styles.buttonCancel} onClick={() => setOpenEdit(false)}>Cancelar</Button>
                            <Button className={styles.buttonDelete} disabled={nameContact == "" || emailContact == ""} onClick={() => deleteOneContact()}>
                                Deletar
                            </Button>
                            <Button className={styles.buttons} disabled={nameContact == "" || emailContact == ""} onClick={() => updateOneContact()}>
                                Atualizar
                            </Button>
                        </DialogActions>
                    </div>
                </Modal>
            </div>
        )
    }

    const modalInsertContact = () => {
        return (
            <div>
                <Modal
                    open={openInsert}
                >
                    <div className={styles.wrapperModalInsertTitle}>
                        <div className={styles.divModalH3}>
                            <h3 className={styles.modalH3}>Adicionar novo contato</h3>
                        </div>
                        <div className={styles.divInputInsert}>
                            <label className={styles.labelFormInput}>Nome do contato*</label>
                            <input className={styles.formInput} type="text" name="nameContact" placeholder="Digite o nome do contato" onChange={d => setNameContact(d.target.value as string)} />
                            <label className={styles.labelFormInput}>Email do contato*</label>
                            <input className={styles.formInput} type="text" name="emailContact" placeholder="Digite o email do contato" onChange={d => setEmailContact(d.target.value as string)} />
                        </div>
                        <DialogActions>
                            <Button className={styles.buttonCancel} onClick={() => setOpenInsert(false)}>Cancelar</Button>
                            <Button className={styles.buttons} disabled={nameContact == "" || emailContact == ""} onClick={() => insertContact()}>
                                Salvar
                            </Button>
                        </DialogActions>
                    </div>
                </Modal>
            </div>
        )
    }

    return (
        <>
            <Card className={styles.card}>
                <CardContent className={styles.cardContent}>
                    <div className={styles.divH3}>
                        <h3 className={styles.h3}>Contatos</h3>
                        <h4 className={styles.h4}>Contatos favoritos</h4>
                    </div>
                    <FormGroup style={{ padding: '20px 40px 0px 40px' }}>
                        {
                            listContact.length != 0
                                ?
                                listContact.map((c) => (

                                    <Tooltip title="Editar contato">
                                        <div className={styles.divContent} onClick={() => setOpenModalEdit(c.name, c.email, c.id)}>
                                            <label className={styles.labelName}>{c.name}</label>
                                            <label className={styles.labelEmail}>{c.email}</label>
                                        </div>
                                    </Tooltip>

                                ))
                                :
                                <label className={styles.labelName}>Nenhum contato adicionado.</label>
                        }
                    </FormGroup>
                </CardContent>
                <div className={styles.divButtonInsert}>
                    <Tooltip title="Adicionar contato">
                        <Button
                            className={styles.buttonInsertContact}
                            onClick={() => setOpenInsert(true)}
                        >
                            <AddOutlinedIcon className={styles.iconInsertItem}></AddOutlinedIcon>
                            <label className={styles.labelButtonInsert}>Adicionar novo contato</label>
                        </Button>
                    </Tooltip>
                </div>
            </Card>
            {modalInsertContact()}
            {modalEditContact()}
        </>
    )
}