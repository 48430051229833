import { RadarEntry, RadarQuadrant, RadarRing } from "@backstage/plugin-tech-radar";
import axios from "axios";

export const baseUrl = () => {
    return "https://backstage-util-prd.azurewebsites.net/api/v1.0/techradar";
    //return "https://localhost:5101/api/v1.0/techradar";
}

const getRings = async () => {
    var rings = await axios.get(baseUrl() + "/get-radar-ring"
        , {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => {
            // console.log('res.data', res.data);
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error: ', { ...e });
        });

    return rings;
}

const getQuadrant = async () => {
    var quadrants = await axios.get(baseUrl() + "/get-radar-quadrant"
        , {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => {
            // console.log('res.data', res.data);
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error: ', { ...e });
        });

    return quadrants;
}

const getEntry = async () => {
    var entrys = await axios.get(baseUrl() + "/get-radar-entry"
        , {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => {
            // console.log('res.data', res.data);
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error: ', { ...e });
        });

    return entrys;
}

export const GetAllRings = () => {
    const newRings = new Array<RadarRing>();

    getRings().then(res => {
        res.data.map((r: any) => {
            newRings.push({
                id: r.id,
                name: r.name,
                color: r.color
            });
        });
    });

    return newRings;
}

export const GetAllQuadrant = () => {
    const newQuandrants = new Array<RadarQuadrant>();

    getQuadrant().then(res => {
        res.data.map((r: any) => {
            newQuandrants.push({
                id: r.id,
                name: r.name
            });
        });
    });

    return newQuandrants;
}

export const GetAllEntry = () => {
    const newEntrys = new Array<RadarEntry>();

    getEntry().then(res => {
        res.data.map((r: any) => {
            newEntrys.push({
                id: r.id,
                key: r.id,
                url: r.url,
                title: r.title,
                quadrant: r.quadrantId,
                description: r.description,
                timeline: r.timeline
            });
        });
    });

    return newEntrys;
}