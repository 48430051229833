import { BackstageOverrides } from '@backstage/core-components';

import {
  BackstageTheme,
  createTheme,
  genPageTheme,
  lightTheme,
  shapes,
} from '@backstage/theme';

const myTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#005677',
    },
    secondary: {
      main: '#565a6e',
    },
    banner: {
      info: '#6EE7B7',
      error: '#8c4351',
      text: '#091926',
      link: '#0095DA',
    },
    navigation: {
      background: '#091926',
      indicator: '#C7C7C7',
      color: '#C7C7C7',
      selectedColor: '#0095DA',
      navItem: {
        hoverBackground: '#0072A7',
      }
    }
  },
  defaultPageTheme: 'home',
  fontFamily: 'Rubik, sans-serif',
  pageTheme: {
    home: genPageTheme({ colors: ['#000', '#343b58'], shape: shapes.wave }),
  },
});

const createCustomThemeOverrides = (
  theme: BackstageTheme,
): BackstageOverrides => {
  return {
    BackstageHeader: {
      header: {
        padding: theme.spacing(3),
        boxShadow: '0 0 8px 3px rgba(20, 20, 20, 0.3)',
        backgroundImage: "none",
        backgroundColor: '#125A97'
      },
      title: {
        color: '#fff'
      },
      subtitle: {
        color: '#fff'
      }
    },
    BackstageInfoCard: {
      header: {
        padding: '20px 40px 20px 40px',
      },
      headerTitle: {
        color: '#0095DA'
      },
    }
  };
};

export const customTheme: BackstageTheme = {
  ...lightTheme,
  ...myTheme,
  overrides: {
    ...lightTheme.overrides,
    ...createCustomThemeOverrides(lightTheme),
  },
};