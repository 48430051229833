import { Header } from '@backstage/core-components';
import { Grid } from '@material-ui/core';
import React from 'react';
import { HomeDragList } from './HomeDragList';
import { useUserProfile } from '@backstage/plugin-user-settings';
import { NewAnnouncementBanner } from '@k-phoen/backstage-plugin-announcements';

export const HomePage = () => {
  var userName = useUserProfile().displayName;
    
  return (
    userName != "" ?
      <>
        <Header title="Início" subtitle=" " />
        <NewAnnouncementBanner />
        <Grid container spacing={1} style={{ margin: '0px', width: '100%' }}>
          <Grid item xs={8}>
            <div style={{ marginTop: '60px', marginLeft: '70px', display: 'flex', flexDirection: 'column', fontFamily: 'Rubik, sans-serif' }}>
              <label style={{ fontSize: '60px' }}>Olá, <b>{userName}!</b></label> 
              <label style={{ fontSize: '30px' }}>Bem vindo ao Backstage.</label>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <img
                src='../../home_fundo.png'
                loading="lazy"
                style={{ float: 'right', padding: '60px', right: '75px', position: 'relative' }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ position: 'relative', bottom: '120px', margin: '0px', width: '100%' }}>
          <Grid item xs={12}>
            <HomeDragList userName={userName}></HomeDragList>
          </Grid>
        </Grid>
      </>
      :
      <>
        <Header title="Início" subtitle=" " />
      </>
  )
};