import React, { useState } from 'react';
import Markdown from 'markdown-to-jsx';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { createStyles, makeStyles, Tooltip } from '@material-ui/core';
import useCollapse from "react-collapsed";
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(() =>
    createStyles({
        textarea: {
            borderLeft: '2px solid rgb(187, 221, 250)',
            outline: 'none !important',
            width: '100%',
            height: '100%',
            overflowX: 'auto',
            fontSize: '17px',
            resize: 'none',
            background: '#fff',
            padding: '25px',
            '&::-webkit-scrollbar': {
                width: '4px',
                height: '4px'
            },
            '&::-webkit-scrollbar-track': {
                background: '#FCFCFC'
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: '13px',
                background: '#AAB1B5',
            }
        },
        divExpandMore: {
            position: 'relative',
            float: 'right',
            width: '24px',
            height: '24px'
        },
        divExpandLess: {
            position: 'relative',
            float: 'right',
            width: '24px',
            height: '24px',
            bottom: '25px',
            left: '25px'
        }
    }));

const MarkComponent = ({ value, language }: any) => {
    return (
        <SyntaxHighlighter language={language ?? null} style={docco}>
            {value ?? ''}
        </SyntaxHighlighter>
    )
}

const SectionCollapseMarkdown = (props: { markdownInput: string }) => {

    const styles = useStyles();

    const [isExpanded, setIsExpanded] = useState(true);

    const handleOnClick = () => {
        setIsExpanded(!isExpanded);
    }

    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded: isExpanded });

    return (
        !isExpanded
            ?
            <div {...getToggleProps({ onClick: handleOnClick })} className={styles.divExpandMore}>
                <Tooltip title="Visualizar documentação"><ExpandMore /></Tooltip>
            </div>
            :
            <div className={styles.textarea}>
                <div {...getToggleProps({ onClick: handleOnClick })} className={styles.divExpandLess}>
                    <Tooltip title="Esconder"><ExpandLess /></Tooltip>
                </div>
                <div {...getCollapseProps()} style={{ maxWidth: '100%' }}>
                    <Markdown
                        components={{
                            code: MarkComponent,
                        }}>
                        {props.markdownInput}
                    </Markdown>
                </div>
            </div>
    )
}

export default SectionCollapseMarkdown;