import React, { useEffect, useState } from 'react';
import { Card, CardContent, createStyles, FormGroup, makeStyles } from '@material-ui/core';
import { Progress } from '@backstage/core-components';
import AirbrakeService from '../../../services/AirbrakeService';
import { IAirbrakeErrorsData } from '../../../types/Airbrake';

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            borderRadius: '20px',
            gap: '14px',
            margin: '30px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
            border: '1px solid #E9E9E9',
        },
        cardContent: {
            padding: '0px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '2px',
                height: '5px'
            },
            '&::-webkit-scrollbar-track': {
                background: '#FCFCFC'
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#AAB1B5',
                borderRadius: '13px'
            }
        },
        divTitle: {
            padding: '0px 0px 0px 40px',
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            fontWeight: 300,
            borderBottom: '1px solid #E9E9E9',
        },
        h3: {
            fontSize: '25px',
            lineHeight: '26px',
            color: '#0095DA',
            marginBottom: '10px'
        },
        labelTrue: {
            fontSize: '15px',
            lineHeight: '26px',
            margin: '0px',
            color: '#00BF85'
        },
        labelFalse: {
            fontSize: '15px',
            lineHeight: '26px',
            margin: '0px',
            color: '#FF3535'
        },
        label: {
            fontFamily: 'Rubik',
            fontSize: '15px',
            lineHeight: '26px',
            marginBottom: '5px',
        }
    }),
);

export const AirbrakeErrors = () => {
    const styles = useStyles();
    const [listAirbrakeErrors, setListAirbrakeErrors] = useState(new Array<IAirbrakeErrorsData>);
    const [refresh, _] = useState(false);
    const [progressStatus, setProgressStatus] = useState(false);

    var url = window.location.href;
    const apiNamePath = url.substring(url.indexOf('/api/') + 5, url.indexOf('/airbrake'));

    useEffect(() => {
        getAirbrakeErrors();
    }, [refresh]);

    const getAirbrakeErrors = () => {
        AirbrakeService.get(apiNamePath)
            .then(res => {
                setListAirbrakeErrors(res.data.data);
                setProgressStatus(true);
            }).catch((e: Error) => {
                console.debug({ ...e });
                throw e.message;
            });
    }

    const content = (airbrakeItems: IAirbrakeErrorsData) => {
        return (
            <>
                <Card className={styles.card}>
                    <CardContent className={styles.cardContent}>
                        <div className={styles.divTitle}>
                            <h3 className={styles.h3}>Ambiente: {airbrakeItems.enviroment}</h3>

                            {airbrakeItems.resolved == true ? <label className={styles.labelTrue}>Status: Resolvido</label> : <label className={styles.labelFalse}>Status: Não resolvido</label>}
                        </div>

                        <FormGroup style={{ padding: '10px 40px 10px 40px' }}>
                            <label className={styles.label}><strong>Data do erro:</strong> {airbrakeItems.createAt}</label>
                            <label className={styles.label}><strong>Severidade:</strong> {airbrakeItems.severity}</label>
                            <label className={styles.label}><strong>Tipo do erro:</strong> {airbrakeItems.type}</label>
                            <label className={styles.label}><strong>Mensagem:</strong> {airbrakeItems.message}</label>
                            <label className={styles.label}><strong>Componente:</strong> {airbrakeItems.component}</label>
                            <label className={styles.label}><strong>Arquivo:</strong> {airbrakeItems.file}</label>
                            <label className={styles.label}><strong>Função:</strong> {airbrakeItems.function}</label>
                            <label className={styles.label}><strong>Linha:</strong> {airbrakeItems.line}</label>
                            <label className={styles.label}><strong>Coluna:</strong> {airbrakeItems.column}</label>
                        </FormGroup>

                    </CardContent>
                </Card>
            </>
        )
    }

    return (
        <>
            {
                listAirbrakeErrors.length != 0
                    ?
                    listAirbrakeErrors.map((a) => (
                        content(a)
                    ))
                    :
                    progressStatus == true && listAirbrakeErrors.length == 0
                        ?
                        <label className={styles.label}>Projeto não possui erros logados no airbrake ou o airbrake não está configurado para esse projeto.</label>
                        :
                        <Progress />
            }
        </>
    )
}