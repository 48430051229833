import React, { useState } from "react";
import useCollapse from "react-collapsed";

import TabOutlinedIcon from '@material-ui/icons/KeyboardTabOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import { makeStyles, createStyles, Tooltip } from "@material-ui/core";

const useStyles = makeStyles(() =>
    createStyles({
        wrapperMenu: {
            width: '25%',
            // height: '97%',
            marginTop: '15px',
            marginBottom: '15px',
            marginLeft: '15px',
            padding: '20px',
            background: '#C2C2C2',
            border: 'none',
            borderRadius: '4px',
        },
        headMenu: {
            display: 'flex',
            fontSize: '25px',
            color: '#091926',
        },
        iconTabOutlined: {
            color: '#0095DA',
            transform: 'rotate(-180deg)',
            cursor: 'pointer',
            '&:hover': {
                background: '#D4F2FF',
                borderRadius: '50%'
            }
        },
        iconMenuOutlined: {
            color: '#0095DA',
            cursor: 'pointer',
            '&:hover': {
                background: '#D8EDFF',
                borderRadius: '50%'
            }
        },
        divMenuOutlined: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            top: '45px',
            left: '37px',
            position: 'relative'
        },
        divIcons: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }),
);

const SectionCollapseMenu = (props: any) => {

    const styles = useStyles();

    const [isExpanded, setIsExpanded] = useState(true);

    const handleOnClick = () => {
        setIsExpanded(!isExpanded);
    }

    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded: isExpanded });

    return (
        !isExpanded
            ?
            <div className={styles.divMenuOutlined}>
                <Tooltip title="Expandir">
                    <MenuOutlinedIcon {...getToggleProps({ onClick: handleOnClick })} className={styles.iconMenuOutlined} />
                </Tooltip>
            </div>
            :
            <div className={styles.wrapperMenu} {...getCollapseProps()}>
                <div>
                    <div className={styles.headMenu}>
                        <div style={{ width: '90%' }}>
                            {props.title}
                        </div>
                        <div className={styles.divIcons}>
                            {/* <SearchOutlinedIcon style={{ color: '#0095DA', marginRight: '10px' }} /> */}
                            <Tooltip title="Esconder">
                                <TabOutlinedIcon {...getToggleProps({ onClick: handleOnClick })} className={styles.iconTabOutlined} />
                            </Tooltip>
                        </div>
                    </div>
                    <div>
                        {props.children}
                    </div>
                </div>
            </div>
    );
}

export default SectionCollapseMenu;