import http from "../http-commons/http-common";
import { getAirbrakeName } from "../http-commons/http-common-backend";
import { IAirbrakeErrors } from "../types/Airbrake";

const get = async (projectName: string) => {
    var name = await getAirbrakeName(projectName);
    return http.get<IAirbrakeErrors>("/airbrake/errors-by-enviroment/" + name)
}

const AirbrakeService = {
    get
}

export default AirbrakeService;