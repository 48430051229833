import { Button, Card, CardActions, CardContent, Chip, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, makeStyles, MenuItem, Select, Theme, Tooltip, Typography } from "@material-ui/core";
import React, { FC, useState } from "react";
import { IJobsData } from "../../types/Jobs";
import CloseIcon from '@material-ui/icons/Close';
import { format } from "date-fns";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import JobsService from "../../services/JobsService";
import SquadService from "../../services/SquadService";
import { ISquadsData } from "../../types/Squads";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContent: {
            minHeight: '250px',
            fontFamily: 'Rubik',
            borderRadius: '20px',
            padding: '5px'
        },
        spanCard: {
            fontWeight: 'bold'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: '#fff',
        },
        buttonCardActionInfo: {
            fontFamily: 'Rubik',
            fontSize: '16px',
            color: '#38A3FF',
            '&:hover': {
                background: '#fff',
            },
            padding: '0px'
        },
        dialog: {
            borderRadius: '20px'
        },
        truncateName: {
            width: '250px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        dialogTitle: {
            color: 'black',
            padding: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
        },
        dialogTitleDiv: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        spanDialog: {
            fontWeight: 'normal'
        },
        divDialogContent: {
            minWidth: '500px',
            display: 'block',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Rubik',
            textAlign: 'center'
        },
        buttonCardActionEdit: {
            color: '#38A3FF',
            padding: '0px',
            '&:hover': {
                background: '#fff',
            },
        },
        cardAction: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },

        divInputInsert: {
            display: 'flex',
            flexDirection: 'column',
            margin: '20px'
        },
        divModalH3: {
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            fontWeight: 300,
            borderBottom: '1px solid #E9E9E9',
        },
        modalH3: {
            fontSize: '25px',
            lineHeight: '26px',
            color: '#0095DA',
            marginBottom: '15px'
        },
        labelFormInput: {
            color: '#091926',
            flex: 'none',
            margin: '4px',
            fontWeight: 400
        },
        formInput: {
            padding: '8px',
            gap: '8px',
            width: '100%',
            height: '40px',
            background: '#FFFFFF',
            border: '1px solid #C2C2C2',
            borderRadius: '4px',
            flex: 'none',
            alignSelf: 'stretch',
            fontSize: '16px',
            fontFamily: 'Rubik, sans-serif'
        },
        buttons: {
            border: '1px solid #0095DA',
            color: '#fff',
            boderRadius: '4px',
            backgroundColor: '#0095DA',
            '&:hover': {
                background: '#0095DA',
            }
        },
        buttonCancel: {
            color: '#0095DA',
            border: '1px solid #0095DA',
            boderRadius: '4px',
            '&:hover': {
                background: '#fff',
            }
        },
        divDialogEdit: {
            minWidth: '500px',
            display: 'block',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Rubik',
        },
    })
);

const IsLate = ({ isLate, status, lastStatus }: any) => {
    if (status == 'Desativado')
        return null;

    if (lastStatus == 'Failed' && status == 'Ativado')
        return <Chip style={{ backgroundColor: 'red', color: 'white' }} label="Falha na execução" />;
    
    if (lastStatus == 'EXECUTANDO' && status == 'Ativado')
        return <Chip style={{ backgroundColor: 'blue', color: 'white' }} label="Executando" />;
    
    if (!isLate)
        return <Chip style={{ backgroundColor: 'green', color: 'white' }} label="Ok" />;

    return <Chip style={{ backgroundColor: 'red', color: 'white' }} label="Atrasado" />;
}

const Disabled = ({ status }: any) => {
    if (status != 'Desativado') return null;
    return (<Chip style={{ backgroundColor: 'orange', color: 'white' }} label={status} />);
}

const JobsCard:
    FC<IJobsData> = ({
        name,
        description,
        endTime,
        isLate,
        lastStatus,
        project,
        rg,
        startTime,
        status,
        subscription,
        timer,
        squad }: IJobsData) => {

        const styles = useStyles();

        const [open, setOpen] = useState(false);
        const [openEdit, setOpenEdit] = useState(false);
        const [_squad, setSquad] = useState("");
        const [_description, setDescription] = useState("");
        const [_project, setProject] = useState("");
        const [squads, setSquads] = useState(new Array<ISquadsData>);

        const getSquads = () => {
            SquadService.get()
                .then(res => {
                    setSquads(res.data.data);
                }).catch((e: Error) => {
                    console.debug({ ...e });
                    throw e.message;
                });
        }

        const UpdateJob = () => {
            JobsService.updateJob(name, _project, _description, _squad);
            closeDialog();
        }

        const openDialog = () => {
            setOpen(true);
        };

        const closeDialog = () => {
            setOpen(false);
            setOpenEdit(false);
        };

        const openEditModal = () => {
            getSquads();
            setProject(project);
            setDescription(description);
            setSquad(squad);
            setOpenEdit(true);
        }

        const dialogContent = () => {
            return (
                <div className={styles.divDialogContent}>
                    <h3>Squad: <span className={styles.spanDialog}><Link href={`/catalog/default/group/${squad}`}> {squad}</Link></span></h3>
                    <h3>Último status: <span className={styles.spanDialog}>{lastStatus == 'Failed' ? 'Falha' : lastStatus == 'EXECUTANDO' ? 'Executando' : 'Sucesso'}</span></h3>
                    <h3>RG: <span className={styles.spanDialog}>{rg}</span></h3>
                    <h3>Subscrição: <span className={styles.spanDialog}>{subscription}</span></h3>
                    <h3>Status: <span className={styles.spanDialog}>{status}</span></h3>
                    <h3>Início: <span className={styles.spanDialog}>{startTime ? format(new Date(startTime), 'dd/MM/yyyy HH:mm:ss') : ''}</span></h3>
                    <h3>Fim: <span className={styles.spanDialog}>{endTime ? format(new Date(endTime), 'dd/MM/yyyy HH:mm:ss') : ''}</span></h3>
                    <h3>Tempo (minutos): <span className={styles.spanDialog}>{timer}</span></h3>
                </div>
            );
        };

        const editContent = () => {
            return (
                <div className={styles.divDialogEdit}>
                    <div className={styles.divInputInsert}>
                        <label className={styles.labelFormInput}>Projeto*</label>
                        <input className={styles.formInput} value={_project} type="text" name="project" placeholder="Digite o projeto" onChange={d => setProject(d.target.value as string)} />
                        <label className={styles.labelFormInput}>Descrição*</label>
                        <input className={styles.formInput} value={_description} type="text" name="description" placeholder="Digite a descrição" onChange={d => setDescription(d.target.value as string)} />
                        <label className={styles.labelFormInput}>Selecione a Squad*</label>
                        <Select
                            required
                            className={styles.formInput}
                            value={_squad}
                            onChange={d => setSquad(d.target.value as string)}
                        >
                            {squads.map((sq) => (
                                <MenuItem key={sq.name} value={sq.name.replace("Squad ", "").replace(" ", "")}>
                                    {sq.name.replace("Squad ", "").replace(" ", "")}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <DialogActions>
                        <Button 
                            className={styles.buttons} 
                            onClick={() => UpdateJob()} 
                            disabled={_project == null || _description == null || _squad == null}>
                            Salvar
                        </Button>
                    </DialogActions>
                </div>
            )
        }

        return (
            <>
                <Card className={styles.cardContent}>
                    <CardContent>
                        <Chip label={project} style={{ background: '#E9E9E9' }} />
                        <IsLate isLate={isLate} status={status} lastStatus={lastStatus} />
                        <Disabled status={status} />
                        <p>{description}</p>
                        <Tooltip title={name}>
                            <p className={styles.truncateName}><span className={styles.spanCard}>Nome:</span> {name}</p>
                        </Tooltip>
                        <p><span className={styles.spanCard}>Squad: </span><Tooltip title="Squad"><Link href={`/catalog/default/group/${squad}`}>{squad}</Link></Tooltip></p>
                        <p><span className={styles.spanCard}>Última execução: </span>{startTime ? format(new Date(startTime), 'dd/MM/yyyy HH:mm:ss') : ''}</p>
                    </CardContent>
                    <CardActions className={styles.cardAction}>
                        <Button className={styles.buttonCardActionEdit} onClick={openEditModal}>
                            <EditIcon></EditIcon>
                        </Button>
                        <Button className={styles.buttonCardActionInfo} onClick={openDialog}>
                            <InfoOutlinedIcon></InfoOutlinedIcon>
                        </Button>
                    </CardActions>
                </Card>
                <Dialog
                    open={open || openEdit}
                    onClose={closeDialog}
                    maxWidth="xl"
                    className={styles.dialog}>
                    <DialogTitle className={styles.dialogTitle}>
                        <div className={styles.dialogTitleDiv}>
                            <Chip label={project} />
                            <IsLate isLate={isLate} status={status} lastStatus={lastStatus} />
                            <Disabled status={status} />
                        </div>
                        {name}
                        <Typography>
                            {description}
                        </Typography>
                        <IconButton
                            className={styles.closeButton}
                            onClick={closeDialog}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {
                            open
                                ?
                                dialogContent()
                                :
                                openEdit
                                    ?
                                    editContent()
                                    :
                                    <></>
                        }
                    </DialogContent>
                </Dialog>
            </>
        )
    }

export default (
    JobsCard
)