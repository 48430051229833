import { DialogContent, DialogContentText } from '@material-ui/core';
import React from 'react';

const DialogContentMessage = (props: { message: string }) => {
    
    return (
        <div>
            <DialogContent>
                <DialogContentText>
                    {props.message}
                </DialogContentText>
            </DialogContent>
        </div>
    );
} 

export default (
    DialogContentMessage
)