import React, { Component, PropsWithChildren } from 'react';
import JobsService from '../../services/JobsService';
import JobsCard from './JobsCard';
import { Page, Header, Content, Progress } from '@backstage/core-components'
import { Chip, Grid, Paper } from '@material-ui/core';
import { IJobsData } from '../../types/Jobs';
import { SearchBar, SearchContextProvider } from '@backstage/plugin-search-react';

interface IState {
    jobs: IJobsData[];
    filtered: IJobsData[];
}
class JobsPage extends Component<any, IState> {
    interval: any = 0;
    constructor(props: any) {
        super(props);
        this.state = {
            jobs: [],
            filtered: []
        }
    }

    componentDidMount(): void {
        this.getJobs();
        this.interval = setInterval(() => {
            this.getJobs();
        }, 60000)
    }

    componentWillUnmount(): void {
        clearInterval(this.interval);
    }

    getJobs() {
        JobsService.get()
            .then(res => {
                this.setState({
                    // jobs: res.data.data.sort((a, b) => a.isLate === b.isLate ? 1 : a.isLate ? -1 : 0),
                    // filtered: res.data.data.sort((a, b) => a.isLate === b.isLate ? 1 : a.isLate ? -1 : 0)

                    jobs: this.lastStatusOrderBy(res.data.data),
                    filtered: this.lastStatusOrderBy(res.data.data),
                });
            }).catch((e: Error) => {
                console.debug({ ...e });
                throw e.message;
            });
    }

    search(value: string) {
        if (!value) {
            this.setState({
                filtered: this.state.jobs
            })
            return
        }
        if (value.length > 2) {
            this.setState({
                filtered: this.lastStatusOrderBy(this.state.jobs).filter((el) => {
                    const val = value.toLowerCase();
                    return el.name?.toLowerCase().match(val) || el.squad?.toLowerCase().match(val) || el.project?.toLowerCase().match(val) || el.lastStatus?.toLowerCase().match(val)
                })
            })
        }
    }

    lastStatusOrderBy = (arr: IJobsData[]) => {
        return arr.sort((a) => a.lastStatus == "Succeeded" || a.lastStatus == "EXECUTANDO" || a.lastStatus == "DESATIVADO" ? 1 : -1);
    }

    statusOrderBy = (a: IJobsData, b: IJobsData) => {
        let statusA = a.status.toUpperCase();
        let statusB = b.status.toUpperCase();

        if (statusA < statusB) return 1;
        if (statusA > statusB) return -1;
        return 0;
    }

    statusJobsCount = (status: string) => {
        var count = this.state.filtered.filter((el) => {
            const val = status.toLowerCase();
            return el.lastStatus?.toLowerCase().match(val) || el.status?.toLowerCase().match(val)
        }).length

        if (status == "EXECUTANDO")
            return "Executando: " + (count).toString();
        else if (status == "Desativado")
            return "Desativado: " + (count).toString();
        else if (status == "Failed")
            return "Falha na execução: " + (count).toString();
        else if (status == "Atrasado")
            return "Atrasado: " + (this.state.filtered.filter((el) => { return el.isLate }).length);
        else
            return "Ok: " + (this.state.filtered.filter((el) => { return !el.isLate && el.lastStatus == "Succeeded" && el.status == "Ativado" }).length);
    }

    render() {
        if (this.state.jobs.length == 0) {
            return (
                <Page themeId='home'>
                    <Header title="Jobs" subtitle="Rotinas de trabalhos da General Claims" />
                    <Content>
                        <Progress />
                    </Content>
                </Page>
            )
        }

        const preFiltered = {
            types: ['widget'],
            term: '',
            filters: {
            },
        };

        return (
            <Page themeId='home'>
                <Header title="Jobs" subtitle="Rotinas de trabalhos da General Claims" />
                <Content>
                    <Chip style={{ backgroundColor: 'green', color: 'white' }} label={this.statusJobsCount("Ok")} />
                    <Chip style={{ backgroundColor: 'blue', color: 'white' }} label={this.statusJobsCount("EXECUTANDO")} />
                    <Chip style={{ backgroundColor: 'red', color: 'white' }} label={this.statusJobsCount("Atrasado")} />
                    <Chip style={{ backgroundColor: 'red', color: 'white' }} label={this.statusJobsCount("Failed")} />
                    <Chip style={{ backgroundColor: 'orange', color: 'white' }} label={this.statusJobsCount("Desativado")} />
                    <SearchContextProvider initialState={preFiltered}>
                        <Paper style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '8px 0',
                            borderRadius: '30px',
                            margin: '15px 0px',
                            fontFamily: 'Rubik'
                        }}>
                            <SearchBar onChange={this.search.bind(this)} clearButton={false} placeholder='Buscar rotinas' />
                        </Paper>
                    </SearchContextProvider>
                    <Wrapper>
                        {
                            this.state.filtered.map((data, index) => {
                                return (
                                    <Grid item xs={3}>
                                        <JobsCard
                                            key={index}
                                            description={data.description}
                                            name={data.name}
                                            endTime={data.endTime}
                                            isLate={data.isLate}
                                            lastStatus={data.lastStatus}
                                            project={data.project}
                                            rg={data.rg}
                                            startTime={data.startTime}
                                            status={data.status}
                                            subscription={data.subscription}
                                            timer={data.timer}
                                            squad={data.squad}
                                        ></JobsCard>
                                    </Grid>
                                )
                            })
                        }
                    </Wrapper>
                </Content>
            </Page>
        )
    }
}

const Wrapper = ({ children }: PropsWithChildren<{}>) => (
    <Grid container spacing={3}>
        {children}
    </Grid>
);

export default (
    <JobsPage></JobsPage>
)