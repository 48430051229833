import { Grid } from "@material-ui/core";
import React from "react";

const HeaderMarkdown = (props: any) => {
    return (
        <>
            <div style={{ textAlign: 'start', borderBottom: '2px solid #BBDDFA', width: '100%', padding: '25px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <p style={{ marginRight: '0px', marginTop: '0px', marginBottom: '0px', marginLeft: '25px', fontSize: '25px' }}>Editor</p>
                        <p style={{ marginRight: '0px', marginTop: '0px', marginBottom: '0px', marginLeft: '25px', fontSize: '15px' }}>Elabore seu texto em <i>markdown</i>.
                            <a href="https://markdown.net.br/sintaxe-basica/" target="_blank">
                                <b style={{ color: '#0095DA' }}> Tutorial</b>
                            </a>
                        </p>
                    </Grid>
                    <Grid item xs={6}>
                        {props.children}
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default HeaderMarkdown;