import http from "../http-commons/http-common-techdocs";
import { ITechDocs } from "../types/TechDocs";

const get = () => {
    return http.get<ITechDocs>('all-document');
}

const TechDocsService = {
    get
}

export default TechDocsService;