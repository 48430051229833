import { makeStyles, createStyles, Modal, Grid, Tooltip } from '@material-ui/core';
import React, { useState } from 'react'
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { getBlobsInContainer, uploadFileToBlob } from './azure-storage-blob';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import SaveAsOutlinedIcon from '@material-ui/icons/SaveOutlined';

const useStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            height: '70%',
            margin: '15px',
            background: '#fff',
            borderRadius: '4px',
            fontFamily: 'Rubik, sans-serif',
        },
        grid: {
            display: 'flex',
            alignItems: 'stretch',
            width: '100%',
            height: '100%'
        },
        head: {
            borderBottom: '2px solid #BBDDFA',
            padding: '20px',
            alignItems: 'center',
            textAlign: 'start',
            fontSize: '20px',
            color: 'black',
            width: '100%',
            fontFamily: 'Rubik, sans-serif',
            height: '15%'
        },
        dropZone: {
            fontSize: '15px',
            width: '100%',
            height: '85%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            textAlign: 'center',
            backgroundColor: '#C2C2C2',
            fontFamily: 'Rubik, sans-serif',
        },
        listZone: {
            borderRadius: '4px',
            fontSize: '15px',
            width: '100%',
            height: '85%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            background: '#fff',
            fontFamily: 'Rubik, sans-serif',
        },
        iconImg: {
            color: '#0095DA',
            cursor: 'pointer',
            '&:hover': {
                background: '#D8EDFF',
                borderRadius: '50%'
            },
            marginRight: '10px'
        },
        iconImgDisabled: {
            float: 'right',
            color: '#E9E9E9',
            marginRight: '10px'
        },
        iconSave: {
            float: 'right',
            color: '#0095DA',
            cursor: 'pointer',
            bottom: '40px',
            position: 'relative',
            right: '10px',
            '&:hover': {
                background: '#D8EDFF',
                borderRadius: '50%'
            }
        },
        iconClose: {
            bottom: '40px',
            position: 'relative',
            float: 'right',
            color: '#0095DA',
            cursor: 'pointer',
            '&:hover': {
                background: '#D8EDFF',
                borderRadius: '50%'
            }
        },
        imgList: {
            width: '100%',
            padding: '5px'
        },
        labelImg: {
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            color: '#091926',
            fontSize: '17px'
        }
    }),
);

const ImagesList = (imagesList: any[]) => {
    const styles = useStyles();

    if (imagesList.length > 0) {
        return imagesList.map((img) =>
            <section className={styles.imgList}>{img.name}</section>
        );
    }

    return <label className={styles.labelImg}>Solte suas imagens aqui.</label>
}

const ImagesListAzure = (imagesList: any[]) => {
    const styles = useStyles();
    return imagesList.map((img) =>
        <section className={styles.imgList}>
            <code style={{ fontFamily: 'Rubik, sans-serif' }}>![]({img})</code>
        </section>
    );
}

const Sleep = (milliseconds: number) => {
    const date = Date.now();
    let currentDate = null;
    do {
        currentDate = Date.now();
    } while (currentDate - date < milliseconds);
}

const UploadFileBlob = (props: { documentName: string }) => {

    const styles = useStyles();

    const [_, setRefresh] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => { setOpen(true); getFiles(); };
    const handleClose = () => { setOpen(false); };
    const [items, setItems] = useState(new Array);
    const [files, setFiles] = useState(new Array);

    const dragOverHandler = (ev: any) => {
        ev.preventDefault();
        setRefresh(false);
    }

    const dropHandler = (ev: any) => {
        ev.preventDefault();

        if (ev.dataTransfer.items) {
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                if (ev.dataTransfer.items[i].kind === 'file') {
                    var file = ev.dataTransfer.items[i].getAsFile();
                    var ext = file.name.split(".").pop();

                    if ((ext.includes('png') || ext.includes('jpg'))) {
                        items.push(file);
                    }
                }
            }
        }
        setRefresh(true);
    }

    const uploadFiles = async () => {
        await uploadFileToBlob(items, props.documentName);
        Sleep(2000);
        getFiles();
        setItems(new Array);
    }

    const getFiles = async () => {
        setFiles(await getBlobsInContainer(props.documentName));
    }

    const EnabledAddImg = () => {
        if (props.documentName == null || props.documentName == "") {
            return (
                <Tooltip title="Adicionar imagens">
                    <AddPhotoAlternateOutlinedIcon className={styles.iconImgDisabled}></AddPhotoAlternateOutlinedIcon>
                </Tooltip>
            )
        }
        else {
            return (
                <Tooltip title="Adicionar imagens">
                    <AddPhotoAlternateOutlinedIcon onClick={handleOpen} className={styles.iconImg}></AddPhotoAlternateOutlinedIcon>
                </Tooltip>
            )
        }
    }

    return (
        <div style={{ float: 'right' }}>
            {EnabledAddImg()}
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid
                    container
                    spacing={3}
                >
                    <div className={styles.wrapper}>
                        <div className={styles.head}>
                            <label style={{ marginRight: '0px', marginTop: '0px', marginBottom: '0px', marginLeft: '25px', fontSize: '25px' }}>Upload das imagens</label><br />
                            <label style={{ marginRight: '0px', marginTop: '0px', marginBottom: '0px', marginLeft: '25px', fontSize: '15px' }}>Solte as imagens que você deseja que apareça na sua documentação na caixa da esquerda. Extensões aceitas: <b><em>.png</em></b> e <b><em>.jpg</em></b></label>

                            <Tooltip title="Fechar">
                                <CloseIcon className={styles.iconClose} onClick={handleClose} />
                            </Tooltip>

                            <Tooltip title="Salvar imagens">
                                <SaveAsOutlinedIcon className={styles.iconSave} onClick={uploadFiles}></SaveAsOutlinedIcon>
                            </Tooltip>
                        </div>

                        <div className={styles.grid}>
                            <Grid item xs={3}>
                                <div className={styles.dropZone} onDrop={ev => dropHandler(ev)} onDragOver={ev => dragOverHandler(ev)}>
                                    {ImagesList(items)}
                                </div>
                            </Grid>

                            <Grid item xs={9}>
                                <div className={styles.listZone}>
                                    {ImagesListAzure(files)}
                                </div>
                            </Grid>
                        </div>

                    </div>
                </Grid>
            </Modal>

        </div>
    )
}

export default (
    UploadFileBlob
)