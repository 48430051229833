
import {
    TechRadarApi,
    TechRadarLoaderResponse,
  } from '@backstage/plugin-tech-radar';

 import { mock } from './tech-radar-data';
  
  export class TedhRadarData implements TechRadarApi {
    async load(): Promise<TechRadarLoaderResponse> {

  
      const data = mock;
  
      return {
        ...data,
        entries: data.entries.map(entry => ({
          ...entry,
          timeline: entry.timeline.map(timeline => ({
            ...timeline,
            date: new Date(timeline.date),
          })),
        })),
      };
    }
  }