import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';

const containerName = `backstage`;
const sasToken = `sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-12-01T19:10:41Z&st=2022-12-23T11:10:41Z&spr=https&sig=U35FnW%2BqSM0Dw40iZiEx%2FLr97C%2FBV8GOtu8vQR%2F8Jg8%3D`;
const storageAccountName = `stggclaimsbackstage`;

const getContainerClient = async () => {
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient: ContainerClient = blobService.getContainerClient(containerName);

  if (!containerClient.exists()) {
    await containerClient.createIfNotExists({
      access: 'container',
    });
  }

  return containerClient;
}

const createBlobInContainer = async (containerClient: ContainerClient, file: File, path: string) => {
  const blobClient = containerClient.getBlockBlobClient(path + "/" + file.name);
  const options = { blobHTTPHeaders: { blobContentType: file.type } };
  await blobClient.uploadData(file, options);
}

export const uploadFileToBlob = async (files: File[], path: string) => {
  const containerClient: ContainerClient = await getContainerClient();

  files.map(async (file) =>
    await createBlobInContainer(containerClient, file, path.replace(' ', ''))
  )
};

export const getBlobsInContainer = async (path: string) => {
  const containerClient: ContainerClient = await getContainerClient();
  const returnedBlobUrls: any[] = [];

  for await (const blob of containerClient.listBlobsFlat()) {
    var pathNameBlob = blob.name.split("/");
    if (pathNameBlob[0] == path.replace(' ', '')) {
      returnedBlobUrls.push(
        `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`
      );
    }
  }

  return returnedBlobUrls;
}
