import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
    createStyles({
        divH3: {
            border: '2px',
            borderStyle: 'none none solid none',
            borderColor: '#E0E6EB',
            width: '100%',
            margin: '3px',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            height: '90px'
        },
        h3: {
            fontFamily: 'Rubik, sans-serif',
            fontSize: '20px',
            padding: '10px',
            width: '50%',
            margin: '0px'
        },
        button: {
            borderRadius: '8px',
            color: '#fff',
            backgroundColor: '#0095DA',
            '&:hover': {
                background: '#125A97',
            },
            height: '45px',
            width: '500px'
        },
        divButton: {
            width: '35%',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
        },
        divFilterH3: {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'start',
            border: '2px',
            borderStyle: 'none none solid none',
            width: '100%'
        },
        h3Filter: {
            fontFamily: 'Rubik, sans-serif',
            fontSize: '20px',
            padding: '3px',
        },
        labelSquad: {
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '21px',
            color: '#091926',
            margin: '4px',
            marginTop: '10px'
        },
        input: {
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '8px',
            gap: '8px',
            width: '100%',
            height: '40px',
            background: '#FFFFFF',
            border: '1px solid #C2C2C2',
            borderRadius: '4px',
            flex: 'none',
            alignSelf: 'stretch',
            fontSize: '16px',
            fontFamily: 'Rubik, sans-serif'
        },
        divHeader: {
            width: '100%',
            display: 'flex',
            paddingBottom: '10px',
            fontFamily: 'Rubik, sans-serif'
        },
        divSearch: {
            width: '65%'
        },
        paper: {
            height: '45px',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            borderRadius: '30px'
        },
        divContent: {
            display: 'flex',
            width: '100%',
            fontFamily: 'Rubik, sans-serif'
        },
        divFilter: {
            width: '19%',
            display: 'flex',
            justifyContent: 'start',
            marginRight: '1%'
        },
        divFilter2: {
            width: '100%',
            padding: '10px',
            backgroundColor: '#e3e3e3',
            height: '350px',
            border: '2px',
            borderRadius: '6px'
        },
        divFilterForm: {
            padding: '4px',
            paddingTop: '15px'
        },
        divFilterButton: {
            display: 'flex',
            marginTop: '30px'
        },
        divDocs: {
            backgroundColor: '#ffffff',
            border: '2px',
            borderRadius: '6px',
            borderStyle: 'solid solid solid solid',
            borderColor: '#E0E6EB',
            width: '80%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    })
);