import http from "../http-commons/http-common";
import { ISquads } from "../types/Squads";

const get = () => {
    return http.get<ISquads>('squads/get-squads')
}

const SquadService = {
    get
}

export default SquadService;