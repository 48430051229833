import React from 'react';
import GoogleFontLoader from 'react-google-font-loader';
import { Navigate, Route } from 'react-router';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  TechDocsIndexPage,
  TechDocsReaderPage,
  techdocsPlugin
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import {
  ExpandableNavigation,
  ReportIssue,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { techDocsPage } from './components/techdocs/TechDocsPage';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import { AlertDisplay, OAuthRequestDialog, SignInPage, SignInProviderConfig } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { PermissionedRoute } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import jobsPage from './components/jobs/JobsPage';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { customTheme } from "./Theme";
import ApplicationsPage from './components/applications/ApplicationsPage';
import { SearchPage } from '@backstage/plugin-search';
import RequestsPage from './components/requests/RequestsPage';
import LiveMarkdown from './components/markdown/LiveMarkdown';
import { AnnouncementsPage } from '@k-phoen/backstage-plugin-announcements';
import { HomePage } from './components/home/HomePage';
import { TechDocsNewPage } from './components/techdocs/TechDocsNewPage';


const microsoftAuthProvider: SignInProviderConfig = {
  id: 'azure-auth-provider',
  title: 'General Claims Credenciais',
  message: 'Entre com o seu email e senha da General Claims.',
  apiRef: microsoftAuthApiRef,
};

const app = createApp({
  apis,
  themes: [{
    id: 'custom-theme',
    title: 'General Claims Theme',
    variant: 'light',
    Provider: ({ children }) => (
      <ThemeProvider theme={customTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    ),
  },
  ],
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        provider={microsoftAuthProvider}
      />
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const routes = (
  <FlatRoutes>
    <Navigate key="/" to="home" />

    <Route path='/home' element={<HomePage></HomePage>}></Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/jobs" element={<CatalogIndexPage initialKind="api" />} > {jobsPage} </Route>
    <Route path="/applications" element={<CatalogIndexPage initialKind="api" />} > {ApplicationsPage} </Route>
    <Route path="/catalog" element={<CatalogIndexPage initialKind="group" />} />
    <Route path="/announcements" element={<AnnouncementsPage title="Anúncios" subtitle='Fique por dentro dos lançamentos e anúncios da General Claims.' />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>

    {/* <Route path="/docs" element={<TechDocsIndexPage />}>
      <CustomTechDocsHome />
    </Route> */}

    <Route path="docs" element={<TechDocsIndexPage />}>
      <TechDocsNewPage></TechDocsNewPage>
    </Route>

    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      {techDocsPage}
      <TechDocsAddons>
        <ExpandableNavigation />
        <ReportIssue />
      </TechDocsAddons>
    </Route>

    <Route path="/create" element={<ScaffolderPage />} />

    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1450} height={700} title="Radar Tech" subtitle='Fique por dentro das tecnologias utilizadas hoje na General Claims' pageTitle='Radar Tech' />}
    />

    <PermissionedRoute
      path="/catalog-import"
      permission={catalogEntityCreatePermission}
      element={<CatalogImportPage />}
    />

    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>

    <Route path="/request">{RequestsPage}</Route>
    <Route path="/markdown">{LiveMarkdown}</Route>

    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
  </FlatRoutes>
);

const App = () => (
  <AppProvider>
    <GoogleFontLoader
      fonts={[
        {
          font: 'Rubik',
          weights: [400],
        }
      ]}
    />
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </AppProvider>
);

export default App;
