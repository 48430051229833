import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';
import { TedhRadarData } from './components/tech-radar/tech-radar-client';
import { techRadarApiRef } from '@backstage/plugin-tech-radar';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => {
      return ScmIntegrationsApi.fromConfig(configApi);
    },
  }),

  ScmAuth.createDefaultApiFactory(),
  createApiFactory(techRadarApiRef, new TedhRadarData()),
];

