import axios from "axios";

export const baseUrl = () => {
    return "https://backstage-util-prd.azurewebsites.net/api/v1.0/checklist/";
    // return "https://localhost:5101/api/v1.0/checklist/";
}

export const getChecklist = async (userName: string) => {
    var checklist = await axios.get(baseUrl() + userName + "/checklist"
        , {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => {
            // console.log('res.data', res.data);
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error: ', { ...e });
        });

    return checklist;
}

export const postChecklistItems = async (userName: string, checklistItem: any) => {
    const item = Array.from(checklistItem);

    var body: any = '{ "saveChecklistItemsCommands": [';

    item.map((c: any) => {
        if (c != null)
            body += '{ "name": "' + c.name + '", "isCheck": ' + c.isCheck + ', "isProcessCheck": ' + c.isProcessCheck + '},';
    });

    body = body.substring(0, body.length - 1);
    body += '] }';

    return await axios.post(baseUrl() + userName + "/save-checklist"
        , body
        , {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => {
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error:', { ...e });
        });
}

export const updateIsCheck = async (userName: string, idChecklistsItems: string, isCheck: boolean) => {
    var body: any = '{ "id": "' + idChecklistsItems + '", "isCheck": ' + isCheck + ' }';

    return await axios.post(baseUrl() + userName + "/update-checklist-item"
        , body
        , {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => {
            // console.log('res.data', res.data);
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error:', { ...e });
        });
}

export const deleteChecklistItems = async (userName: string) => {
    return await axios.delete(baseUrl() + userName + "/delete-checklist-items"
        , {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => {
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error: ', { ...e });
        });
}