import axios from "axios";

const baseUrl = () => {
    return "https://backstage.gclaims.com.br/api/catalog/entities/by-name/api/default/";
}

export const getAirbrakeName = async (projectName: string) => {
    var name = await axios.get(baseUrl() + projectName, {
        headers: {
            'Content-type': 'application/json'
        }
    })
        .then(res => {
            return res.data.metadata.annotations['airbrakename'];
        })
        .catch((e: Error) => {
            console.log('error: ', { ...e });
        });

    if (name == undefined)
        return "xxxxxx";
    return name;
}