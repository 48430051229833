import React, { FC, useState } from 'react';
import { Card, CardContent, createStyles, Dialog, DialogContent, makeStyles } from "@material-ui/core";
import { IRequestData } from '../../types/Requests';
import FeedIcon from '@material-ui/icons/AssignmentOutlined';

const useStyles = makeStyles(() =>
    createStyles({
        circleIcon: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: '#F1F8FE',
            height: '50px',
            width: '50px',
            left: '145px',
            top: '16px',
            borderRadius: '50%',
            padding: '14px'
        },
        card: {
            width: '350px',
            height: '260px',
            borderRadius: '10px',
            padding: '0px, 0px, 23px, 0px',
            gap: '14px',
            margin: '30px',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.5)'
        },
        cardContent: {
            margin: '0',
            padding: '0px'
        },
        divIcon: {
            height: '50px',
            background: '#0095DA'
        },
        divLink: {
            padding: '10px',
            textAlign: 'center',
            fontFamily: 'Rubik, sans-serif',
            height: '80%',
            cursor: 'pointer'
        },
        h3Link: {
            color: '#0095DA',
            fontSize: '25px'
        },
        labelLink: {
            fontSize: '17px',
            cursor: 'pointer'
        },
        dialogContent: {
            padding: '0px',
            height: '100%',
            width: '100%',
            '&::-webkit-scrollbar': {
                display: 'none',
            }
        },
    }),
);

const RequestsCard: FC<IRequestData> = ({
    title,
    description,
    link
}) => {

    const styles = useStyles();

    const [open, setOpen] = useState(false);
    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    return (
        <>
            <Card className={styles.card}>
                <CardContent className={styles.cardContent}>
                    <div className={styles.divIcon}>
                        <div className={styles.circleIcon}>
                            <FeedIcon></FeedIcon>
                        </div>
                    </div>
                    <div onClick={openDialog} className={styles.divLink}>
                        <h3 className={styles.h3Link}>{title}</h3>
                        <label className={styles.labelLink}>{description}</label>
                    </div>
                </CardContent>
            </Card>
            <Dialog
                open={open}
                onClose={closeDialog}
                PaperProps={{
                    style: { height: '100%', width: '100%', padding: '0px' }
                }}
            >
                <DialogContent className={styles.dialogContent} style={{ padding: '0px' }}>
                    <iframe src={link} width="100%" height="100%"></iframe>
                    <script async src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"></script>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default (
    RequestsCard
);