import React from 'react';
import { Page } from '@backstage/core-components';
import {
  TechDocsReaderPageHeader,
  TechDocsReaderPageContent,
} from '@backstage/plugin-techdocs';

const DefaultTechDocsPage = () => {

  return (
    <div>
      <Page themeId="documentation" >
        <TechDocsReaderPageHeader />
        <div>
          <TechDocsReaderPageContent withSearch={false} />
        </div>
      </Page>
    </div>
  );
};

export const techDocsPage = <DefaultTechDocsPage />;