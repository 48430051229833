import http from "../http-commons/http-common";
import { IJobs } from "../types/Jobs";

const get = () => {
    return http.get<IJobs>('/resource/get-logic-apps')
}

const updateJob = (jobName: string, project: string, description: string, squad: string) => {
    var body = '{ "jobName": "' + jobName + '", "project": "' + project + '", "description": "' + description + '", "squad": "' + squad + '" }';

    return http.put('/resource/update-jobs', body)
        .then(res => {
            res.data;
        })
        .catch((e: Error) => {
            console.log('error: ', { ...e });
        });
}

const JobsService = {
    get
    , updateJob
}

export default JobsService;