import axios from "axios";
import { ISquads } from "../interface/ISquads";

const baseApiUrl = () => {
    return "https://backstage-gclaims.azurewebsites.net/api/squads/get-squads/";
}

export const getAllSquads = async (showAll?: boolean) => {
    var squadsApi = await axios.get(baseApiUrl(),
        {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => {
            // console.log('res.data', res.data);
            return res.data.data;
        })
        .catch((e: Error) => {
            console.debug('error: ', { ...e });
        });

    var squads = new Array<ISquads>();

    if (showAll)
        squads.push({ name: "Todas" });
        
    if (squadsApi != null) {
        squadsApi.map((s: any) => {
            squads.push({
                name: s.name.replace("Squad ", "").replace(" ", "")
            });
        });
    }

    return squads;
}