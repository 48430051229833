import { createStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DragDropContext } from "react-beautiful-dnd";
import { Announcements } from './components/Announcements';
import { Calendar } from './components/Calendar';
import { Checklist } from './components/Checklist';
import { Contacts } from './components/Contacts';
import { Notes } from './components/Notes';
import { getHomeConfiguration, postHomeConfiguration } from './context/HomeContext';
import DraggableElement from './HomeDraggableElement';
import { IHomeConfiguration } from './interface/IHomeConfiguration';

const useStyles = makeStyles(() =>
    createStyles({
        divDropContext: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridGap: '8px'
        }
    }),
);

const GetHomeConfiguration = async (userName: string) => {
    return await getHomeConfiguration(userName).then(res => {
        if (res.data == null)
            return null;
        return res.data.homeLists;
    });
}

const PostHomeConfiguration = async (userName: string, homeLists: any) => {
    return await postHomeConfiguration(userName, homeLists);
}

const getItem = (prefix: any, arrayContent: Array<IHomeConfiguration>) => {
    return arrayContent.map((value) => {
        if (prefix != value.prefix)
            return {};

        return {
            id: value.id,
            prefix: value.prefix,
            nameList: value.nameList,
            content: value.content
        };
    });
}

const removeFromList = (list: any[], index: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(index, 1);
    return [removed, result];
};

const addToList = (list: any[], index: any, element: any, listId: any) => {
    const result = Array.from(list);
    element.prefix = listId;
    result.splice(index, 0, element);
    return result;
};

const lists = ["1", "2", "3"];

const generateLists = (arrayContent: Array<IHomeConfiguration>) =>
    lists.reduce(
        (acc, listKey) => ({ ...acc, [listKey]: getItem(listKey, arrayContent) }),
        {}
    );

const contentElement = (nameElement: string, userName: string) => {
    switch (nameElement) {
        case 'SmallCalendar':
            return <Calendar userName={userName}></Calendar>;
        case 'SmallChecklist':
            return <Checklist userName={userName}></Checklist>;
        case 'SmallNotes':
            return <Notes userName={userName}></Notes>;
        case 'SmallAnnouncements':
            return <Announcements></Announcements>
        default:
            return <Contacts userName={userName}></Contacts>;
    }
}

export const HomeDragList = (props: { userName: string }) => {
    const styles = useStyles();
    const [arrayCont, setArrayCont] = useState(new Array<IHomeConfiguration>);
    const [elements, setElements] = useState(generateLists(arrayCont));
    const [user, setUser] = useState("");

    useEffect(() => {
        getArrayContent();
        setElements(generateLists(arrayCont));
    }, [user]);

    async function getArrayContent() {
        let homeConfig = await GetHomeConfiguration(props.userName);
        let homeConfigCache = new Array<IHomeConfiguration>;
        let homeConfigBD = new Array<IHomeConfiguration>;

        homeConfigCache.push(
            {
                id: "-1"
                , prefix: "1"
                , nameList: "SmallAnnouncements"
                , content: contentElement("SmallAnnouncements", props.userName)
            },
            {
                id: "-1"
                , prefix: "1"
                , nameList: "SmallChecklist"
                , content: contentElement("SmallChecklist", props.userName)
            },
            {
                id: "-2"
                , prefix: "2"
                , nameList: "SmallNotes"
                , content: contentElement("SmallNotes", props.userName)
            },
            {
                id: "-2"
                , prefix: "3"
                , nameList: "SmallContacts"
                , content: contentElement("SmallContacts", props.userName)
            }
        );

        if (homeConfig != null) {
            homeConfig.map((r: any) => {
                homeConfigBD.push({
                    id: r.id
                    , prefix: r.idList
                    , nameList: r.typeList
                    , content: contentElement(r.typeList, props.userName)
                });
            });
        }
        else {
            await PostHomeConfiguration(props.userName, homeConfigCache);
        }

        let newContent = false;
        homeConfigCache.map((cache) => {
            let exists = homeConfigBD.filter(bd => bd.nameList == cache.nameList);

            if (exists.length == 0) {
                homeConfigBD.push(cache);
                newContent = true;
            }
        });

        if (newContent)
            await PostHomeConfiguration(props.userName, homeConfigBD);

        setArrayCont(homeConfigBD);
        setUser(props.userName);
    }

    const onDragEnd = async (result: any) => {
        if (!result.destination) {
            return;
        }
        const listCopy: any = { ...elements };

        const sourceList = listCopy[result.source.droppableId];

        const [removedElement, newSourceList] = removeFromList(
            sourceList,
            result.source.index
        );

        listCopy[result.source.droppableId] = newSourceList;

        const destinationList = listCopy[result.destination.droppableId];

        listCopy[result.destination.droppableId] = addToList(
            destinationList,
            result.destination.index,
            removedElement,
            result.destination.droppableId
        );

        var newList = listCopy[1].concat(listCopy[2]).concat(listCopy[3]);
        await PostHomeConfiguration(props.userName, newList);
        setElements(listCopy);
    };

    return (
        <div>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className={styles.divDropContext}>
                    {lists.map((listKey) => (
                        <DraggableElement
                            elements={elements[listKey]}
                            key={listKey}
                            prefix={listKey}
                        />
                    ))}
                </div>
            </DragDropContext>
        </div>
    )
}