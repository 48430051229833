import axios from "axios";

export const baseUrl = () => {
    return 'https://backstage-tech-docs-prd.azurewebsites.net/api/v1.0/projects/';
    // return 'https://localhost:5101/api/v1.0/projects/';
}

export const token = () => {
    return 'JNxR41/o7ERba+LdehpX4iRnmBTY/MxIUkGctbjix6BFJYy8AdG6By60/um9dQf/GaOK8fIdmE2hd2emN/T8UbYDXOxIB+JAFV7iCHBIO4loMN3JIz/sMrc5T44hdOkfFdG/NiRhmBsfGnjwrNHA0lI7fT/4FAll/gidHgd8hpIEWYBYi072eSLGK3dWhv5igkWCObQQn78nOny7EZX7vg==';
}

export const postIndex = async (docName: string, docDescription: string, ownerName: string, items: any[], author?: string) => {
    let patKey = token();

    var json: any = '{ "description": "' + docDescription + '", "author": "' + author + '", "lastUpdateAuthor": "' + author + '", "owner": "' + ownerName + '", "menuItems": [';
    items.map((item, index) => {
        if (index == 0) {
            json += '{"fileName": "index.md", "itemName": "' + item.title + '"},'
        }
        else {
            json += '{"fileName": "' + item.pathName + '", "itemName": "' + item.title + '"},'
        }
    });

    json = json.substring(0, json.length - 1);
    json += '] }';

    return await axios.post(baseUrl() + docName + '/mkdocs',
        json
        , {
            headers: {
                'Content-type': 'application/json',
                'Pat-Key': patKey
            }
        })
        .then(res => {
            // console.log('postIndex', res.data);
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error:', { ...e });
        });
}

export const getIndexNavItems = async (docName: string) => {
    let patKey = token();

    var listItems: any[] = await axios.get(baseUrl() + docName + '/mkdocs-nav-items'
        , {
            headers: {
                'Content-type': 'application/json',
                'Pat-Key': patKey
            }
        })
        .then(res => {
            // console.log('getIndexNavItems', res.data);
            return res.data.data;
        })
        .catch((e: Error) => {
            console.log('error:', { ...e });
        });

    return listItems;
}

export const postDocument = async (docName: string, docPathName: string, content: string) => {
    let patKey = token();
    let contentJson = '{ "content": ' + JSON.stringify(content) + '}';

    return await axios.post(baseUrl() + docName + '/documents/' + docPathName,
        contentJson
        , {
            headers: {
                'Content-type': 'application/json',
                'Pat-Key': patKey
            }
        })
        .then(res => {
            // console.log('postDocument', res.data);
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error:', { ...e });
        });
}

export const getDocumentContent = async (docName: string, docPathName: string) => {
    let patKey = token();

    var docContent: string = await axios.get(baseUrl() + docName + '/documents/' + docPathName
        , {
            headers: {
                'Content-type': 'application/json',
                'Pat-Key': patKey
            }
        })
        .then(res => {
            // console.log('getDocumentContent', res.data);
            return res.data.data;
        })
        .catch((e: Error) => {
            console.log('error:', { ...e });
        });

    return docContent;
}

export const deleteDocument = async (docName: string) => {
    let patKey = token();

    return await axios.delete(baseUrl() + docName
        , {
            headers: {
                'Content-type': 'application/json',
                'Pat-Key': patKey
            }
        })
        .then(res => {
            // console.log('deleteDocument', res.data);
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error:', { ...e });
        })
}

export const deleteIndex = async (docName: string, indexName: string) => {
    let patKey = token();

    return await axios.delete(baseUrl() + docName + '/documents/' + indexName
        , {
            headers: {
                'Content-type': 'application/json',
                'Pat-Key': patKey
            }
        })
        .then(res => {
            // console.log('deleteIndex', res.data);
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error:', { ...e });
        })
}