import React from "react";
import { Droppable } from "react-beautiful-dnd";
import ListItem from "./ListItem";

const DraggableElement = (props: { prefix: string, elements: any }) => (
    <div>
        <Droppable droppableId={`${props.prefix}`}>
            {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                    {props.elements.map((item: { id: React.Key }, index: any) => (
                        <ListItem key={item.id} item={item} index={index} />
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    </div>
);

export default DraggableElement;