import axios from "axios";

export const baseUrl = () => {
    return "https://backstage-util-prd.azurewebsites.net/api/v1.0/home/";
    // return "https://localhost:5101/api/v1.0/home/";
}

export const getHomeConfiguration = async (userName: string) => {
    var homeConfiguration = await axios.get(baseUrl() + userName + "/configuration-list"
        , {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => {
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error: ', { ...e });
        });

    return homeConfiguration;
}

export const postHomeConfiguration = async (userName: string, homeList: any) => {
    const homeLists = Array.from(homeList);

    var body: any = '{ "saveHomeConfigurationLists": [';

    homeLists.map((item: any) => {
        if (item != null && item.id != null)
            body += '{ "idList": "' + item.prefix + '", "typeList": "' + item.nameList + '"},'
    });

    body = body.substring(0, body.length - 1);
    body += '] }';

    console.log('body', body);

    return await axios.post(baseUrl() + userName + "/save-home-configuration"
        , body
        , {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => {
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error:', { ...e });
        });
}