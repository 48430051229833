import http from '../http-commons/http-common';
import { IApplications } from '../types/Applications';

const get = () => {
    return http.get<IApplications>('/resource/get-applications');
};

const updateApplication = (applicationName: string, project: string, squad: string) => {
    var body = '{ "applicationName": "' + applicationName + '", "project": "' + project + '", "squad": "' + squad + '" }';

    return http.put('/resource/update-applications', body)
        .then(res => {
            res.data;
        })
        .catch((e: Error) => {
            console.log('error: ', { ...e });
        });
}

const ApplicationsService = {
    get
    , updateApplication
};

export default ApplicationsService;