import React from 'react';
import { Button, Card, CardContent, Grid, Link, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import ShareOutlined from '@material-ui/icons/FileCopyOutlined';
import { ITechDocs } from './interface/ITechDocs';

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            width: '390px',
            height: '500px',
            borderRadius: '10px',
            gap: '14px',
            margin: '20px',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.5)',
        },
        cardContent: {
            margin: '0px',
            padding: '0px'
        },
        divTitle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80px',
            background: 'gray',
            cursor: 'pointer',
            padding: '10px',
        },
        divLabels: {
            padding: '25px',
            fontFamily: 'Rubik, sans-serif',
            height: '80%'
        },
        h3Title: {
            color: '#fff',
            fontSize: '22px',
            display: 'flex',
            textAlign: 'center'
        },
        labelTitle: {
            fontWeight: 'bold',
            fontSize: '15px',
        },
        labelReadDoc: {
            cursor: 'pointer',
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#fff',
        },
        labelContent: {
            fontSize: '15px',
        },
        dialogContent: {
            padding: '0px',
            height: '100%',
            width: '100%',
            '&::-webkit-scrollbar': {
                display: 'none',
            }
        },
        divActions: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            background: '#0095DA',
            padding: '10px',
            height: '60px',
        },
        buttonShare: {
            marginRight: '10px',
            backgroundColor: '#0095DA',
            '&:hover': {
                background: '#0095DA',
            },
        }
    }),
);

export default function TechDocsCard(props: { data: ITechDocs }) {
    const styles = useStyles();

    const handleCopyLink = () => {
        const tempInput = document.createElement('input');
        tempInput.value = `${window.location.href}/default/component/${props.data.projectName}`;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
    };

    const addZero = (num: number) => {
        if (num <= 9)
            return "0" + num;
        else
            return num;
    }

    const lastUpdateDate = (dateFormat: Date) => {
        return (
            addZero(dateFormat.getDate()) + "/" + (addZero((dateFormat.getMonth() + 1))) + "/" + (dateFormat.getFullYear()) + " às " + dateFormat.getHours() + ":" + addZero(dateFormat.getMinutes())
        );
    }

    return (
        <Grid>
            <Card className={styles.card}>
                <CardContent className={styles.cardContent}>
                    <Tooltip title="Ler documentação">
                        <a href={'/docs/default/component/' + props.data.projectName}>
                            <div className={styles.divTitle}>
                                <MenuBookOutlinedIcon style={{ color: '#fff', marginRight: '15px' }}></MenuBookOutlinedIcon>
                                <h3 className={styles.h3Title}>{props.data.projectName}</h3>
                            </div>
                        </a>
                    </Tooltip>
                    <div className={styles.divLabels}>
                        <label className={styles.labelTitle}>Descrição: </label><label className={styles.labelContent}>{props.data.description.slice(0, 400)}</label><br />
                        <label className={styles.labelTitle}>Squad: </label><Tooltip title="Squad"><label className={styles.labelContent}><Link href={`/catalog/default/group/${props.data.owner}`}>{props.data.owner}</Link></label></Tooltip><br />
                        <label className={styles.labelTitle}>Data criação: </label><label className={styles.labelContent}>{lastUpdateDate(new Date(props.data.createdAt))}</label><br />
                        <label className={styles.labelTitle}>Autor: </label><Tooltip title="Autor"><label className={styles.labelContent}><Link href={`/catalog/default/user/${props.data.author}`}>{props.data.author}</Link></label></Tooltip><br />
                        <label className={styles.labelTitle}>Data últ. atualização: </label><label className={styles.labelContent}>{lastUpdateDate(new Date(props.data.updatedAt))}</label><br />
                        <label className={styles.labelTitle}>Autor da últ. atualização: </label><Tooltip title="Autor"><label className={styles.labelContent}><Link href={`/catalog/default/user/${props.data.author}`}>{props.data.lastUpdateAuthor}</Link></label></Tooltip><br />
                    </div>
                </CardContent>
                <div className={styles.divActions}>
                    <a href={`/docs/default/component/${props.data.projectName}`}>
                        <Tooltip title="Ler documentação"><VisibilityIcon style={{ color: '#fff', marginRight: '10px', marginTop: '5px' }} /></Tooltip>
                    </a>
                    <Button className={styles.buttonShare} onClick={() => { handleCopyLink(); }}>
                        <Tooltip title="Copiar link da documentação">
                            <ShareOutlined style={{ color: '#fff' }}></ShareOutlined>
                        </Tooltip>
                    </Button>
                    <a href={`/markdown?docName=${props.data.projectName}`}>
                        <Tooltip title="Editar documentação"><EditIcon style={{ color: '#fff', marginTop: '5px' }} /></Tooltip>
                    </a>
                </div>
            </Card>
        </Grid>
    )
}