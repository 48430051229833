import { Content, Header, Page } from '@backstage/core-components';
import { Grid } from '@material-ui/core';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import RequestsCard from './RequestsCard';
import { IRequestData } from '../../types/Requests';
import RequestsService from '../../services/RequestsService';

const RequestsPage = () => {

    const [datas, setDatas] = useState(new Array<IRequestData>);
    const [error, setError] = useState("");

    useEffect(() => {
        getRequests();
    }, []);

    const getRequests = () => {
        RequestsService.get()
        .then(res => {
            setDatas(res.data.data);
        }).catch((e: Error) => {
            console.debug({ ...e })
            setError(e.message);
        })
    }

    return (
        <Page themeId='home'>
            <Header title="Solicitar" subtitle="Solicite melhorias, pedidos de infraestrutura, reporte de bugs e mais."></Header>
            <Content>
                <Wrapper>
                    {
                        error == "" 
                        ?
                        datas.map((data) => {
                            return (
                                <Grid>
                                    <RequestsCard
                                        title={data.title}
                                        description={data.description}
                                        link={data.link}
                                    ></RequestsCard>
                                </Grid>
                            )
                        })
                        :
                        <label>{error}</label>
                    }
                </Wrapper>
            </Content>
        </Page>
    )
}

const Wrapper = ({ children }: PropsWithChildren<{}>) => (
    <Grid container spacing={2}>
        {children}
    </Grid>
);

export default (
    <RequestsPage></RequestsPage>
)