import { Content, Header, Page, Progress } from '@backstage/core-components';
import { SearchBar, SearchContextProvider } from '@backstage/plugin-search-react';
import { Chip, Grid, Paper } from '@material-ui/core';
import React, { Component, PropsWithChildren } from 'react';
import ApplicationsService from '../../services/ApplicationsService';
import { IApplicationsData } from '../../types/Applications';
import ApplicationsCard from './ApplicationsCard';

interface IState {
    applications: IApplicationsData[];
    filtered: IApplicationsData[];
}

class ApplicationsPage extends Component<any, IState> {
    interval: any = 0;
    constructor(props: any) {
        super(props);
        this.state = {
            applications: [],
            filtered: []
        };
    }

    componentDidMount(): void {
        this.getApplications();
        this.interval = setInterval(() => {
            this.getApplications();
        }, 6e4)
    }

    componentWillUnmount(): void {
        clearInterval(this.interval);
    }

    getApplications() {
        ApplicationsService.get()
            .then(res => {
                this.setState({
                    applications: this.sortByStatus(res.data.data),
                    filtered: this.sortByStatus(res.data.data),
                })
            })
            .catch((e: Error) => {
                console.debug({ ...e });
                throw e.message;
            })
    }

    sortByStatus(arr: IApplicationsData[]) {
        return arr.sort((a) => a.lastStatus == 200 ? 1 : -1);
    }

    search(value: string) {
        if (!value) {
            this.setState({
                filtered: this.state.applications
            })
            return
        }
        if (value.length > 2) {
            this.setState({
                filtered: this.sortByStatus(this.state.applications).filter((el) => {
                    const val = value.toLowerCase();
                    return el.name.toLowerCase().match(val) || el.squad?.toLowerCase().match(val) || el.projeto?.toLowerCase().match(val) || el.lastStatus.toString().match(val)
                })
            })
        }
    }

    statusApplicationsCount = (status: any) => {
        var count = this.state.filtered.filter((el) => {
            return el.lastStatus == status;
        }).length

        return "Com status " + status + ": " + (count).toString() + " aplicações";
    }

    render() {
        if (this.state.applications.length == 0) {
            return (
                <Page themeId='home'>
                    <Header title="Aplicações" subtitle="Aplicações da General Claims"></Header>
                    <Content>
                        <Progress />
                    </Content>
                </Page>
            )
        }

        const preFiltered = {
            types: ['widget'],
            term: '',
            filters: {
            },
        };

        return (
            <Page themeId='home'>
                <Header title="Aplicações" subtitle="Aplicações da General Claims"></Header>
                <Content>
                    <Chip style={{ backgroundColor: 'green', color: 'white' }} label={this.statusApplicationsCount(200)} />
                    <Chip style={{ backgroundColor: 'red', color: 'white' }} label={this.statusApplicationsCount(401)} />
                    <Chip style={{ backgroundColor: 'red', color: 'white' }} label={this.statusApplicationsCount(403)} />
                    <Chip style={{ backgroundColor: 'red', color: 'white' }} label={this.statusApplicationsCount(404)} />
                    <Chip style={{ backgroundColor: 'red', color: 'white' }} label={this.statusApplicationsCount(405)} />
                    <Chip style={{ backgroundColor: 'orange', color: 'white' }} label={this.statusApplicationsCount(500)} />
                    <Chip style={{ backgroundColor: 'orange', color: 'white' }} label={this.statusApplicationsCount(503)} />
                    <SearchContextProvider initialState={preFiltered}>
                        <Paper style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '8px 0',
                            borderRadius: '30px',
                            margin: '15px 0px',
                            fontFamily: 'Rubik'
                        }}>
                            <SearchBar clearButton={false} onChange={this.search.bind(this)} placeholder='Buscar aplicações' />
                        </Paper>
                    </SearchContextProvider>
                    <Wrapper>
                        {
                            this.state.filtered.map((data, index) => {
                                return (
                                    <Grid item xs={3}>
                                        <ApplicationsCard
                                            ambiente={data.ambiente}
                                            estado={data.estado}
                                            lastStatus={data.lastStatus}
                                            name={data.name}
                                            ok={data.ok}
                                            projeto={data.projeto}
                                            rg={data.rg}
                                            subscricao={data.subscricao}
                                            squad={data.squad}
                                            lastUpdateDate={data.lastUpdateDate}
                                            key={index + 'appl'}
                                        ></ApplicationsCard>
                                    </Grid>
                                )
                            })
                        }
                    </Wrapper>
                </Content>
            </Page>
        )
    }
}

const Wrapper = ({ children }: PropsWithChildren<{}>) => (
    <Grid container spacing={3}>
        {children}
    </Grid>
);

export default (
    <ApplicationsPage></ApplicationsPage>
)