import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Checkbox, createStyles, DialogActions, FormControlLabel, FormGroup, makeStyles, Modal, Tooltip } from '@material-ui/core';
import { deleteNote, getNotes, postNote, updateNote } from '../context/NoteContext';
import { INote } from '../interface/INote';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            maxHeight: '500px',
            borderRadius: '20px',
            gap: '14px',
            margin: '30px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
            border: '1px solid #E9E9E9',
            width: '100%',
        },
        cardContent: {
            padding: '0px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '5px'
            },
            '&::-webkit-scrollbar-track': {
                background: '#FCFCFC'
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#AAB1B5',
                borderRadius: '13px',
            }
        },
        divH3: {
            padding: '0px 40px 0px 40px',
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            fontWeight: 300,
            borderBottom: '1px solid #E9E9E9'
        },
        h3: {
            fontSize: '25px',
            lineHeight: '26px',
            color: '#0095DA'
        },
        iconInsertItem: {
            color: '#0095DA',
            width: '28px',
            height: '28px',
            backgroundColor: '#FFFFFF',
            border: 'none',
            borderRadius: '4px'
        },
        labelName: {
            fontSize: '18px',
            marginLeft: '5px'
        },
        divContent: {
            fontSize: '16px',
            marginTop: '10px',
            display: 'flex',
        },
        divButtonInsert: {
            marginTop: '10px',
            padding: '20px 40px 20px 40px',
            borderTop: '1px solid #E9E9E9',
        },
        buttonInsertNote: {
            margin: '0px',
            minWidth: '28px',
            height: '28px',
            padding: '0px',
            '&:hover': {
                backgroundColor: 'transparent'
            }
        },
        iconInsertNote: {
            color: '#0095DA',
            width: '28px',
            height: '28px',
            backgroundColor: '#FFFFFF',
            border: 'none',
            borderRadius: '4px',
        },
        labelButtonNote: {
            marginLeft: '15px',
            cursor: 'pointer',
            fontWeight: 400,
            fontSize: '15px',
        },
        wrapperModalInsertTitle: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            height: '600px',
            margin: '30px',
            padding: '20px 40px 20px 40px',
            background: '#fff',
            borderRadius: '20px',
            fontFamily: 'Rubik',
            fontStyle: 'normal',
        },
        divInputInsert: {
            display: 'flex',
            flexDirection: 'column',
            margin: '20px'
        },
        divModalH3: {
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            fontWeight: 300,
            borderBottom: '1px solid #E9E9E9',
        },
        modalH3: {
            fontSize: '25px',
            lineHeight: '26px',
            color: '#0095DA',
            marginBottom: '15px'
        },
        labelFormInput: {
            color: '#091926',
            flex: 'none',
            margin: '4px',
            fontWeight: 400
        },
        formInput: {
            padding: '8px',
            gap: '8px',
            width: '100%',
            height: '40px',
            background: '#FFFFFF',
            border: '1px solid #C2C2C2',
            borderRadius: '4px',
            flex: 'none',
            alignSelf: 'stretch',
            fontSize: '16px',
            fontFamily: 'Rubik, sans-serif'
        },
        formTextarea: {
            resize: 'none',
            height: '300px',
            background: '#FFFFFF',
            border: '1px solid #C2C2C2',
            borderRadius: '4px',
            fontSize: '16px',
            fontFamily: 'Rubik, sans-serif'
        },
        buttons: {
            border: '1px solid #0095DA',
            color: '#fff',
            boderRadius: '4px',
            backgroundColor: '#0095DA',
            '&:hover': {
                background: '#0095DA',
            }
        },
        buttonCancel: {
            color: '#0095DA',
            border: '1px solid #0095DA',
            boderRadius: '4px',
            '&:hover': {
                background: '#fff',
            }
        },
        buttonDelete: {
            color: '#fff',
            border: '1px solid #FF3535',
            boderRadius: '4px',
            backgroundColor: '#FF3535',
            '&:hover': {
                background: '#FF3535',
            }
        }
    }),
);

const GetNotes = async (userName: string) => {
    return await getNotes(userName).then(res => {
        if (res.data == null)
            return null;
        return res.data.notes;
    });
}

const generateColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const Notes = (props: { userName: string }) => {
    const styles = useStyles();

    const [openInsert, setOpenInsert] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [listNote, setListNote] = useState(new Array<INote>);
    const [refresh, setRefresh] = useState(false);
    const [noteName, setNoteName] = useState("");
    const [noteContent, setNoteContent] = useState("");
    const [idNote, setIdNote] = useState("");

    useEffect(() => {
        getNotesContent();
    }, [refresh]);

    const setOpenModalEdit = (name: string, email: string, id?: string) => {
        setNoteName(name);
        setNoteContent(email);
        setIdNote(id as string);
        setOpenEdit(true);
    }

    async function getNotesContent() {
        var bdNotes = await GetNotes(props.userName);
        var notes = new Array<INote>;

        if (bdNotes != null) {
            bdNotes.map((n: INote) => {
                notes.push(
                    {
                        id: n.id
                        , noteName: n.noteName
                        , noteContent: n.noteContent
                    }
                )
            });
        }

        setListNote(notes);
    }

    async function insertNote() {
        var newContact: INote = {
            noteName: noteName
            , noteContent: noteContent
        }

        listNote.push(newContact);
        await postNote(props.userName, listNote);
        setOpenInsert(false);
        setNoteName("");
        setNoteContent("");
        setRefresh(!refresh);
    }

    async function deleteOneNote() {
        await deleteNote(props.userName, idNote);
        setOpenEdit(false);
        setNoteName("");
        setNoteContent("");
        setIdNote("");
        setRefresh(!refresh);
    }

    async function updateOneNote() {
        await updateNote(props.userName, idNote, noteName, noteContent);
        setOpenEdit(false);
        setNoteName("");
        setNoteContent("");
        setIdNote("");
        setRefresh(!refresh);
    }

    const modalEditNote = () => {
        return (
            <div>
                <Modal
                    open={openEdit}
                >
                    <div className={styles.wrapperModalInsertTitle}>
                        <div className={styles.divModalH3}>
                            <h3 className={styles.modalH3}>Adicionar nova nota</h3>
                        </div>
                        <div className={styles.divInputInsert}>
                            <label className={styles.labelFormInput}>Nome da nota*</label>
                            <input className={styles.formInput} value={noteName} type="text" name="noteName" placeholder="Digite o nome da nota" onChange={d => setNoteName(d.target.value as string)} />
                            <label className={styles.labelFormInput}>Conteúdo*</label>
                            <textarea className={styles.formTextarea} value={noteContent} name="noteContent" placeholder="Digite o conteúdo da nota" onChange={d => setNoteContent(d.target.value as string)} />
                        </div>
                        <DialogActions>
                            <Button className={styles.buttonCancel} onClick={() => setOpenEdit(false)}>Cancelar</Button>
                            <Button className={styles.buttonDelete} disabled={noteContent == "" || noteName == ""} onClick={() => deleteOneNote()}>
                                Deletar
                            </Button>
                            <Button className={styles.buttons} disabled={noteContent == "" || noteName == ""} onClick={() => updateOneNote()}>
                                Atualizar
                            </Button>
                        </DialogActions>
                    </div>
                </Modal>
            </div>
        )
    }

    const modalInsertNote = () => {
        return (
            <div>
                <Modal
                    open={openInsert}
                >
                    <div className={styles.wrapperModalInsertTitle}>
                        <div className={styles.divModalH3}>
                            <h3 className={styles.modalH3}>Adicionar nova nota</h3>
                        </div>
                        <div className={styles.divInputInsert}>
                            <label className={styles.labelFormInput}>Nome da nota*</label>
                            <input className={styles.formInput} type="text" name="noteName" placeholder="Digite o nome da nota" onChange={d => setNoteName(d.target.value as string)} />
                            <label className={styles.labelFormInput}>Conteúdo*</label>
                            <textarea className={styles.formTextarea} name="noteContent" placeholder="Digite o conteúdo da nota" onChange={d => setNoteContent(d.target.value as string)} />
                        </div>
                        <DialogActions>
                            <Button className={styles.buttonCancel} onClick={() => setOpenInsert(false)}>Cancelar</Button>
                            <Button className={styles.buttons} disabled={noteName == "" || noteContent == ""} onClick={() => insertNote()}>
                                Salvar
                            </Button>
                        </DialogActions>
                    </div>
                </Modal>
            </div>
        )
    }

    return (
        <>
            <Card className={styles.card}>
                <CardContent className={styles.cardContent}>
                    <div className={styles.divH3}>
                        <h3 className={styles.h3}>Bloco de notas</h3>
                    </div>
                    <FormGroup style={{ padding: '20px 40px 0px 40px' }}>
                        {
                            listNote.length != 0
                                ?
                                listNote.map((n) => (
                                    <Tooltip title="Visualizar nota">
                                        <div className={styles.divContent} onClick={() => setOpenModalEdit(n.noteName, n.noteContent, n.id)}>
                                            <label style={{ borderLeft: '4px solid ' + generateColor() + '' }}></label>
                                            <label className={styles.labelName}>{n.noteName}</label>
                                        </div>
                                    </Tooltip>
                                ))
                                :
                                <label className={styles.labelName}>Nenhum checklist criado.</label>
                        }
                    </FormGroup>
                </CardContent>
                <div className={styles.divButtonInsert}>
                    <Tooltip title="Adicionar nova nota">
                        <Button
                            className={styles.buttonInsertNote}
                            onClick={() => setOpenInsert(true)}
                        >
                            <AddOutlinedIcon className={styles.iconInsertNote}></AddOutlinedIcon>
                            <label className={styles.labelButtonNote}>Adicionar nova nota</label>
                        </Button>
                    </Tooltip>
                </div>
            </Card>
            {modalInsertNote()}
            {modalEditNote()}
        </>
    )
}