import axios from "axios";

export const baseUrl = () => {
    return "https://backstage-util-prd.azurewebsites.net/api/v1.0/contact/";
    // return "https://localhost:5101/api/v1.0/contact/";
}

export const getContacts = async (userName: string) => {
    var contacts = await axios.get(baseUrl() + userName + "/contacts"
    , {
        headers: {
            'Content-type': 'application/json'
        }
    })
    .then(res => {
        return res.data;
    })
    .catch((e: Error) => {
        console.log('error: ', { ...e });
    });

    return contacts;
}

export const postContact = async (userName: string, contactList: any) => {
    const contacts = Array.from(contactList);

    var body: any = '{ "saveContactCommand": [';

    contacts.map((c: any) => {
        if (c != null)
            body += '{ "name": "' + c.name + '", "email": "' + c.email + '"},';
    });

    body = body.substring(0, body.length - 1);
    body += '] }';

    return await axios.post(baseUrl() + userName + "/save-contact"
        , body
        , {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => {
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error:', { ...e });
        });
}

export const deleteContact = async (userName: string, idContact: string) => {
    return await axios.post(baseUrl() + userName + "/delete-contact"
        , '{ "idContact": "' + idContact + '" }'
        , {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => {
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error: ', { ...e });
        });
}

export const updateContact = async (userName: string, idContact: string, nameContact: string, emailContact: string) => {
    var body = '{ "idContact": "' + idContact + '", "name": "' + nameContact + '", "email": "' + emailContact + '" }';

    return await axios.post(baseUrl() + userName + "/update-contact"
        , body
        , {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => {
            return res.data;
        })
        .catch((e: Error) => {
            console.log('error: ', { ...e });
        });
}