import React, { useState } from "react";
import useCollapse from "react-collapsed";

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles, createStyles, Tooltip } from "@material-ui/core";

const useStyles = makeStyles(() =>
    createStyles({
        collapsible: {
            backgroundColor: '#C2C2C2',
            display: 'flex',
            flexDirection: 'row',
            marginTop: '20px',
            padding: '8px 16px',
            alignSelf: 'stretch',
            color: '#091926',
            fontSize: '17px',
            cursor: 'pointer',
            width: '100%',
            border: '1px',
            borderRadius: '4px',
            textAlign: 'left',
            outline: 'none',
        }
    }),
);

const SectionCollapseSimple = (props: any) => {

    const styles = useStyles();

    const [isExpanded, setIsExpanded] = useState(true);

    const handleOnClick = () => {
        setIsExpanded(!isExpanded);
    }

    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded: isExpanded });

    return (
        <div className="collapsible">
            <div className={styles.collapsible} {...getToggleProps({ onClick: handleOnClick })}>
                {props.title}
                {isExpanded ? <Tooltip title="Esconder"><ExpandLess /></Tooltip> : <Tooltip title="Expandir"><ExpandMore /></Tooltip>}
            </div>
            <div {...getCollapseProps()}>
                <div>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default SectionCollapseSimple;