import React, { PropsWithChildren, useEffect, useState } from 'react';
import { ITechDocs } from './interface/ITechDocs';
import { Content, Header, Page, Progress } from '@backstage/core-components';
import { Button, Grid, MenuItem, Paper, Select, Tooltip } from '@material-ui/core';
import TechDocsCard from './TechDocsCard';
import { SearchBar, SearchContextProvider } from '@backstage/plugin-search-react';
import FilterAltOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import { useStyles } from './css/TechDocsNewPageCss';
import { ISquads } from '../squads/interface/ISquads';
import TechDocsService from '../../services/TechDocsService';
import SquadService from '../../services/SquadService';
import { ISquadsData } from '../../types/Squads';

export const TechDocsNewPage = () => {
    const styles = useStyles();
    const [listDocuments, setListDocuments] = useState(new Array<ITechDocs>);
    const [filtered, setFiltered] = useState(new Array<ITechDocs>);
    const [squadFilter, setSquadFilter] = useState("Todas");
    const [lastUpdateFilter, setLastUpdateFilter] = useState("");
    const [refresh, _] = useState(false);
    const [squads, setSquads] = useState(new Array<ISquads>());

    useEffect(() => {
        getAllDocs();
        getSquads();
    }, [refresh]);

    const getSquads = () => {
        SquadService.get()
            .then(res => {
                var squadsAll: ISquadsData[] = res.data.data;
                squadsAll.push({ name: "Todas", isEnabled: true });
                setSquads(squadsAll);
            }).catch((e: Error) => {
                console.debug({ ...e });
                throw e.message;
            });
    }

    const getAllDocs = () => {
        TechDocsService.get()
            .then(res => {
                setListDocuments(res.data.data);
                setFiltered(res.data.data);
            }).catch((e: Error) => {
                console.debug({ ...e });
                throw e.message;
            });
    }

    const search = (value: string) => {
        if (!value) {
            setFiltered(listDocuments);
            return;
        }

        if (value.length > 2) {
            let filter = listDocuments.filter((el) => {
                const val = value.toLowerCase();
                return el.projectName.toLowerCase().match(val) || el.owner.toLowerCase().match(val) || el.author?.toString().toLowerCase().match(val) || el.description.toLowerCase().match(val);
            });

            setFiltered(filter);
        }
    }

    const filterSearch = (squad: string, lastUpdate: string) => {
        if (squad || lastUpdate) {

            let filter = listDocuments.filter((el) => {
                if (squad == "Todas")
                    return listDocuments;

                const val = squad.toLowerCase();
                return el.owner.toLowerCase().match(val);
            });

            filter = filter.filter((el) => {
                return el.updatedAt.toString().match(lastUpdate);
            });

            setFiltered(filter);
        }
    }

    const preFiltered = {
        types: ['widget'],
        term: '',
        filters: {
        },
    };

    const orderProjectName = (a: ITechDocs, b: ITechDocs) => {
        let projectNameA = a.projectName.toUpperCase();
        let projectNameB = b.projectName.toUpperCase();

        if (projectNameA < projectNameB) return -1;
        if (projectNameA > projectNameB) return 1;
        return 0;
    }

    if (listDocuments.length != 0) {
        return (
            <Page themeId='home'>
                <Header title="Documentações" subtitle="Documentações técnicas da General Claims"></Header>
                <Content>
                    <div className={styles.divHeader}>
                        <div className={styles.divSearch}>
                            <SearchContextProvider initialState={preFiltered}>
                                <Paper className={styles.paper}>
                                    <SearchBar clearButton={false} onChange={search.bind(this)} placeholder='Buscar documentações' />
                                </Paper>
                            </SearchContextProvider>
                        </div>
                        <div className={styles.divButton}>
                            <Tooltip title="Nova documentação">
                                <a href='/markdown'>
                                    <Button className={styles.button}>+ Nova documentação</Button>
                                </a>
                            </Tooltip>
                        </div>
                    </div>
                    <div className={styles.divContent}>

                        {/* Filter */}
                        <div className={styles.divFilter}>
                            <div className={styles.divFilter2}>
                                <div className={styles.divFilterH3}>
                                    <h3 className={styles.h3Filter}> <FilterAltOutlinedIcon></FilterAltOutlinedIcon> Filtros</h3>
                                </div>
                                <div className={styles.divFilterForm}>
                                    <label className={styles.labelSquad}>Squad</label>
                                    <Select value={squadFilter} onChange={d => setSquadFilter(d.target.value as string)} className={styles.input}>
                                        {squads.map((sq) => (
                                            <MenuItem key={sq.name} value={sq.name.replace("Squad ", "").replace(" ", "")}>
                                                {sq.name.replace("Squad ", "").replace(" ", "")}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <br></br>
                                    <label className={styles.labelSquad}>Última atualização</label>
                                    <input className={styles.input} type="date" id="dataInput" onChange={d => setLastUpdateFilter(d.target.value)}></input>
                                </div>
                                <div className={styles.divFilterButton}>
                                    <Tooltip title="Filtrar">
                                        <Button onClick={() => filterSearch(squadFilter, lastUpdateFilter)} className={styles.button}>Filtrar</Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>

                        {/* Docs */}
                        {
                            <div className={styles.divDocs}>
                                {
                                    <Wrapper>
                                        <div className={styles.divH3}>
                                            <h3 className={styles.h3}>Todos os documentos ({filtered.length})</h3>
                                        </div>
                                        {
                                            filtered.sort(orderProjectName).map((data) => {
                                                return (
                                                    <div>
                                                        <TechDocsCard data={data}></TechDocsCard>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Wrapper>
                                }
                            </div>
                        }
                    </div>
                </Content>
            </Page>
        )
    }
    else {
        return (
            <Page themeId='home'>
                <Header title="Documentações" subtitle="Documentações técnicas da General Claims"></Header>
                <Content>
                    <Progress />
                </Content>
            </Page>
        )
    }
}

const Wrapper = ({ children }: PropsWithChildren<{}>) => (
    <Grid container spacing={1}>
        {children}
    </Grid>
);