import { Draggable } from "react-beautiful-dnd";
import React from "react";

const ListItem = (props: { item: any, index: any }) => {
    return (
        <Draggable draggableId={`${props.item.id}`} index={props.index}>
            {(provided) => {
                return (
                    <div
                        style={{ padding: '20px', borderRadius: '6px', background: 'white', margin: '0 0 8px 0', display: 'grid', gridGap: '20px', flexDirection: 'column' }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {props.item.content}
                        </div>
                    </div>
                );
            }}
        </Draggable>
    );
};

export default ListItem;
