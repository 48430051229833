import { Card, CardContent, createStyles, FormGroup, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            height: '600px',
            borderRadius: '20px',
            gap: '14px',
            margin: '30px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
            border: '1px solid #E9E9E9',
            width: '100%',
            padding: '0px 0px 20px 40px'
        },
        cardContent: {
            padding: '0px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '5px'
            },
            '&::-webkit-scrollbar-track': {
                background: '#FCFCFC'
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#AAB1B5',
                borderRadius: '13px',
            }
        },
        div: {
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            fontWeight: 300,
            marginRight: '40px'
        },
        divH3: {
            borderBottom: '1px solid #E9E9E9',
        },
        h3: {
            fontSize: '25px',
            lineHeight: '26px',
            color: '#0095DA',
        },
        divContent: {
            fontSize: '16px',
            marginTop: '10px'
        }
    }),
);

const generateColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const Calendar = (props: { userName: string }) => {
    const styles = useStyles();

    return (
        <>
            <Card className={styles.card}>
                <CardContent className={styles.cardContent}>
                    <FormGroup>
                        <div className={styles.div}>
                            <div className={styles.divH3}>
                                <h3 className={styles.h3}>Hoje, 9 de março</h3>
                            </div>
                            <div className={styles.divContent}>
                                <label style={{ borderLeft: '4px solid ' + generateColor() + '' }}></label>
                                <label style={{ marginLeft: '5px' }}>Planning Estruturante</label>
                                <label style={{ float: 'right' }}>16h30m</label> <br />

                                <label style={{ borderLeft: '4px solid ' + generateColor() + '' }}></label>
                                <label style={{ marginLeft: '5px' }}>Planning Estruturante</label>
                                <label style={{ float: 'right' }}>16h30m</label> <br />
                            </div>

                            <div className={styles.divH3}>
                                <h3 className={styles.h3}>Sexta, 10 de março</h3>
                            </div>
                            <div className={styles.divContent}>
                                <label style={{ borderLeft: '4px solid ' + generateColor() + '' }}></label>
                                <label style={{ marginLeft: '5px' }}>Planning Estruturante</label>
                                <label style={{ float: 'right' }}>16h30m</label> <br />
                            </div>
                        </div>
                    </FormGroup>
                </CardContent>
            </Card>
        </>
    )
}