import http from "../http-commons/http-common";
import { IRequest }  from "../types/Requests";

const get = () => {
    return http.get<IRequest>('/request/get-requests')
}

const RequestsService = {
    get
}

export default RequestsService;